import React, { useCallback, useState } from 'react';
import cn from 'classnames';

import styles from './WorkTimeAddForm.module.scss';
import { Button, Col, DatePicker, Form, message, Row, Space, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import WorkTimeWeekly from '../../../containers/WorkTimeWeekly';
import { flatten, map, mapObjIndexed, pipe, values as RValues } from 'ramda';
import { useCreateNewWorkTimeMutation } from '../../../store/apis/workTime';
import ContentLoader from '../../../components/ContentLoader';
import { useUserTimezone } from '../../../store/slicers/user';
import { useNavigate } from 'react-router';
import routes from '../../../router/routes';
import dayjs from 'dayjs';
import SchedulesOutsideWorkTimeModal from '../../../containers/SchedulesOutsideWorkTimeModal';

const { Title, Text } = Typography;

const WorkTimeAddForm = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const [newWorkTime, setNewWorkTime] = useState(null);

  const [
    createWorkTime,
    {
      isLoading: isCreateWorkTimeLoading,
      isFetching: isCreateWorkTimeFetching,
    },
  ] = useCreateNewWorkTimeMutation();

  const { timezone } = useUserTimezone();

  const handleSubmit = useCallback(async (values) => {
    const preparedTimeSpans = pipe(
      mapObjIndexed((timeArray, weekDayId) => (
        map(
          (hourId) => ({
            weekDayId: Number(weekDayId),
            hourId,
            tz: timezone,
          }),
          timeArray,
        )
      )),
      RValues,
      flatten,
    )(values.timeSpans);

    const newWorkTime = {
      startTime: values.startTime.startOf('date').format(),
      endTime: values.endTime?.startOf('date').format() ?? null,
      timeSpans: preparedTimeSpans
    };

    setNewWorkTime(newWorkTime);
  }, [timezone]);

  const confirmChangeWorkTime = useCallback(async () => {
    try {
      await createWorkTime(newWorkTime).unwrap();
      setNewWorkTime(null);
      navigate(routes.workTime());
      message.success(t('Новое расписание добавлено'));
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [newWorkTime, t, navigate, createWorkTime]);

  const resetNewWorkTime = useCallback(() => {
    setNewWorkTime(null);
  }, []);

  return (
    <div className={styles.formWrapper}>
      <SchedulesOutsideWorkTimeModal
        open={Boolean(newWorkTime)}
        workSchedule={newWorkTime}
        onClose={resetNewWorkTime}
        onConfirm={confirmChangeWorkTime}
        confirmLoading={isCreateWorkTimeLoading || isCreateWorkTimeFetching}
      />
      {
        (isCreateWorkTimeLoading || isCreateWorkTimeFetching)
          && (
            <div className={styles.formWrapperLoader}>
              <ContentLoader />
            </div>
        )
      }
      <Title level={3}>{t('Добавить новое время работы')}</Title>

      <Form
        onFinish={handleSubmit}
        layout="vertical"
      >
        <Row gutter={12}>
          <Col md={8}>
            <Form.Item
              name="startTime"
              label={t('Дата начала действия расписания')}
              required
              rules={[{ required: true }]}
            >
              <DatePicker
                format="DD.MM.YYYY"
                style={{ width: '100%' }}
                placeholder={t('Выберите начальную дату')}
              />
            </Form.Item>
          </Col>
          <Col md={8}>
            <Form.Item
              name="endTime"
              label={t('Дата окончания действия расписания')}
              tooltip={t('Вы можете оставить конечную дату пустой\. Тогда это будет означать, что данное расписание будет действовать постоянно')}
            >
              <DatePicker
                format="DD.MM.YYYY"
                style={{ width: '100%' }}
                placeholder={t('Выберите конечную дату')}
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="timeSpans"
          label={t('Расписание')}
          required
          rules={[
            () => ({
              validator: (_, value) => {
                const flattedArray = flatten(RValues(value));
                if (flattedArray.length === 0) return Promise.reject(new Error(t('Нужно выбрать хотя бы одно время работы')));
                return Promise.resolve();
              },
              validateTrigger: 'onFinish',
            }),
          ]}
        >
          <WorkTimeWeekly />
        </Form.Item>
        <Form.Item>
          <Button htmlType="submit" type="primary">
            {t('Добавить')}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default WorkTimeAddForm;
