import { useCallback, useState } from 'react';

export default () => {
  const [isOpen, setIsOpen] = useState(false);

  const show = useCallback(() => {
    setIsOpen(true);
  }, []);
  const hide = useCallback(() => {
    setIsOpen(false);
  }, []);

  return [isOpen, show, hide];
}
