import React, { useCallback } from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import routes from '../../router/routes';
import queryString from 'query-string';
import { useStartLessonMutation } from '../../store/apis/schedule';
import dayjs from 'dayjs';
import { Button, message, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import useSockets from '../../hooks/useSockets';

const StartLesson = ({
  scheduleId,
  roomId,
  studentId,
  lessonTime,
}) => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const socket = useSockets();

  const [startLessonTrigger, { isLoading: isStartLessonLoading, isFetching: isStartLessonFetching }] = useStartLessonMutation();

  const startLesson = useCallback(async () => {
    const startLessonTime = dayjs(lessonTime).subtract(15, 'minute');
    const now = dayjs();

    if (now < startLessonTime) {
      message.info(t('Начать можно будет за 10 минут до урока'));
      return;
    }

    try {
      await startLessonTrigger({ body: { lessonTime }, scheduleId }).unwrap();
      socket.emit('directSend', {
        userType: 'student',
        userId: studentId,
        type: 'lessonStart',
        data: 'lessonStart',
      });

      navigate({
        pathname: routes.room(),
        search: queryString.stringify({
          roomId, studentId,
        }),
      });
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [startLessonTrigger, lessonTime, scheduleId, navigate, roomId, studentId]);

  if (isStartLessonLoading || isStartLessonFetching) return <LoadingOutlined />;

  return (
    <Tooltip title={t('Начать урок')}>
      <Button type="link" onClick={startLesson}>
        🎥
      </Button>
    </Tooltip>
  );
};

export default StartLesson;
