import { isRejectedWithValue } from '@reduxjs/toolkit';
import { message, Modal } from 'antd';
import i18n from '../../localization/i18n';
import router from '../../router';
import routes from '../../router/routes';
import { removeCredentials } from '../../utils';

export const rtkQueryErrorLogger = (api) => (next) => (action) => {
  if (!isRejectedWithValue(action)) return next(action);

  const response = action.payload;

  if (response.status === 401) {
    message.error(i18n.t('Срок вашей сессии истек'));
    router.navigate(routes.root());
    removeCredentials();
    return next(action);
  }

  if (response.status === 500) {
    message.error(i18n.t('Произошла ошибка сервера'));
    return next(action);
  }

  if (response.data) {
    if (response.data.type === 'form') {
      const {  details  } = response.data;
      message.error(`${response.status} - ${response.data.message}`);
      Modal.error({
        title: i18n.t('Исправьте ошибки'),
        content: (
          <div>
            {details.map(({ name, errors }, i) => (
              <div key={i}>{name}: {errors.join(', ')}</div>
            ))}
          </div>
        ),
      });
      return next(action);
    }

    if (response.data.type === 'simple') {
      message.error(response.data.message);
      return next(action);
    }

  }

  if (response.status === 'CORS') {
    message.error(i18n.t('Произошла ошибка подключения, проверьте соединение с интернетом'));
    return next(action);
  }

  message.error(`${response.status} - ${response.error}`);

  return next(action);
}
