import React from 'react';
import ReactDOM from 'react-dom/client';

import dayjs from 'dayjs';
import localizedFormat from 'dayjs/plugin/localizedFormat';
import weekday from 'dayjs/plugin/weekday';
import duration from 'dayjs/plugin/duration';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import relativeTime from 'dayjs/plugin/relativeTime';
import calendar from 'dayjs/plugin/calendar';
import 'antd/dist/reset.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './localization/i18n';
import './index.scss';
import 'dayjs/locale/ru';
import 'dayjs/locale/uz-latn';
import 'dayjs/locale/en';
import localforage from 'localforage';
import * as Sentry from '@sentry/react';
import { CaptureConsole, HttpClient } from '@sentry/integrations';

dayjs.extend(localizedFormat);
dayjs.extend(duration);
dayjs.extend(weekday);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(calendar);

dayjs.locale('ru');

Sentry.init({
  dsn: "https://545db75329f88bb0656716fd59c2536f@o4505743067840512.ingest.sentry.io/4505754467762176",
  integrations: [
    new HttpClient({
      failedRequestStatusCodes: [[500, 505], 507, 404],
      failedRequestTargets: [
        'https://api.bilimdosh.uz',
        'https://tutor.bilimdosh.uz',
      ],
    }),
    new CaptureConsole({
      levels: ['error'],
    }),
    new Sentry.BrowserTracing({
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: ["https:api.bilimdosh.uz"],
    }),
    new Sentry.Replay(),
  ],
  enabled: process.env.REACT_APP_ENV === 'production',
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <App />
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
