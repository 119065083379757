// import cn from 'classnames';

import styles from './RoomVideoControls.module.scss';
import { FiMic, FiMicOff } from 'react-icons/fi';
import React from 'react';
import { useMediaState } from '../../../store/slicers/room';
import useMedia from '../../../rtc/hooks/useMedia';

const AudioButton = () => {
  const mediaState = useMediaState();
  const MediaDevice = useMedia();

  return (
    <button
      className={styles.controlsButton}
      onClick={() => {
        MediaDevice.toggle('Audio');
      }}
    >
      {mediaState.isAudioEnabled ? <FiMic/> : <FiMicOff />}
    </button>
  );
};

export default AudioButton;
