import { invalidateOn } from '../utils';
import apiRoutes from '../../apiRoutes';
import { mainApi } from './mainApi';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getNotifications: build.query({
      query: (params) => ({
        url: apiRoutes.notifications(),
        params,
      }),
      providesTags: ['NOTIFICATIONS'],
    }),

    readOneNotification: build.mutation({
      query: ({ id, params }) => ({
        url: apiRoutes.readOneNotification(id),
        method: 'PUT',
      }),
      onQueryStarted: async ({ id, params }, { dispatch, queryFulfilled }) => {
        const putResult = dispatch(
          mainApi.util.updateQueryData('getNotifications', params, (draft) => {
            draft.results.forEach((notification) => {
              if (notification.id === id) notification.isRead = true;
            });
          })
        );

        try {
          await queryFulfilled
        } catch (e) {
          putResult.undo();
        }
      },
      // invalidatesTags: invalidateOn({ success: ['NOTIFICATIONS'] }),
    }),
    readAllNotifications: build.mutation({
      query: (params) => ({
        url: apiRoutes.readAllNotification(),
        method: 'PUT',
      }),
      invalidatesTags: invalidateOn({ success: ['NOTIFICATIONS'] }),
      onQueryStarted: async (params, { dispatch, queryFulfilled }) => {
        const putResult = dispatch(
          mainApi.util.updateQueryData('getNotifications', params, (draft) => {
            draft.results.forEach((notification) => {
              notification.isRead = true;
            });
          })
        );

        try {
          await queryFulfilled
        } catch (e) {
          putResult.undo();
        }
      },
      // invalidatesTags: invalidateOn({ success: ['NOTIFICATIONS'] }),
    }),
  }),
})

export const {
  useGetNotificationsQuery,
  useReadOneNotificationMutation,
  useReadAllNotificationsMutation,
} = mainApi;
