import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './WorkTime.module.scss';
import { useTranslation } from 'react-i18next';
import { useDeleteWorkTimeMutation, useGetMyWorkTimeQuery } from '../../../store/apis/workTime';
import useQueryString from '../../../hooks/useQueryString';
import { Button, Col, Divider, Drawer, message, Popconfirm, Row, Skeleton, Space, Tabs, Typography } from 'antd';
import ContentLoader from '../../../components/ContentLoader';
import ContentError from '../../../components/ContentError';
import ContentEmpty from '../../../components/ContentEmpty';
import { getDateDiapasonLabel } from '../../../utils';
import WorkTimeWeekly from '../../../containers/WorkTimeWeekly';
import { InfoCircleOutlined, InfoOutlined } from '@ant-design/icons';
import useToggle from '../../../hooks/useToggle';
import { useNavigate } from 'react-router';
import routes from '../../../router/routes';

const { Title, Text } = Typography;

const WorkTime = () => {
  const [t] = useTranslation();
  const [isDrawerOpen, openDrawer, closeDrawer] = useToggle();
  const navigate = useNavigate();

  const [
    deleteWorkTime,
    {
      isLoading: isDeleteWorkTimeLoading,
      isFetching: isDeleteWorkTimeFetching,
    },
  ] = useDeleteWorkTimeMutation();

  const {
    data: workTimes,
    isLoading: isWorkTimesLoading,
    isFetching: isWorkTimesFetching,
    error: workTimesError,
  } = useGetMyWorkTimeQuery({ page: -1 });

  const navigateToCreate = useCallback(() => {
    navigate(routes.workTimeCreate());
  }, [navigate]);

  const handleDeleteWorkTime = useCallback((id) => async () => {
    try {
      await deleteWorkTime(id).unwrap();
      message.success(t('Расписание удалено'));
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [deleteWorkTime, t]);

  if (isWorkTimesLoading || isWorkTimesFetching) return <ContentLoader label={t('Загружаю Ваше расписание')} />
  if (workTimesError) return <ContentError error={workTimesError} />

  const { results } = workTimes;
  if (!results || results.length === 0) {
    return (
      <ContentEmpty
        label={t('Мы тут ничего не смогли найти')}
        action={
          <Button
            type="primary"
            onClick={navigateToCreate}
          >
            {t('Добавить расписание')}
          </Button>
        }
      />
    )
  }

  const tabItems = results.map(({ id, startTime, endTime, timeSpans }) => ({
    label: getDateDiapasonLabel(startTime, endTime),
    key: id,
    children: (
      <div>
        <WorkTimeWeekly value={timeSpans} readOnly />
        <div style={{ textAlign: 'right', marginTop: '2em' }}>
          <Popconfirm
            title={t('Вы уверены что хотите удалить расписание?')}
            description={t('Расписание \"{{schedule}}\" будет удалено', { schedule: getDateDiapasonLabel(startTime, endTime) })}
            onConfirm={handleDeleteWorkTime(id)}
            okText={t('Да, удалить расписание')}
            cancelText={t('Отменить')}
          >
            <Button
              danger
            >
              {t('Удалить расписание {{schedule}}', { schedule: getDateDiapasonLabel(startTime, endTime) })}
            </Button>
          </Popconfirm>
        </div>
      </div>
    ),
  }));

  return (
    <div>
      <Drawer size="large" title={t('Справочная информация')} placement="right" onClose={closeDrawer} open={isDrawerOpen}>
        <Text>{t('В этом разделе Вы можете установить ваше расписания для бронирования учениками')}</Text>
        <Divider />
        <Title level={5}>{t('Добавление нового времени работы')}</Title>
        <Text>{t('Вы можете добавить новое время работы, нажав на кнопку Добавить расписание')}</Text>
      </Drawer>
      <div className={styles.header}>
        <Space align="start" style={{ width: '100%' }}>
          <Title level={3}>{t('Ваше время работы')}</Title>
          <Button
            shape="circle"
            icon={<InfoCircleOutlined />}
            size="small"
            type="text"
            onClick={openDrawer}
          />
        </Space>
        <Button
          type="primary"
          style={{ marginLeft: 'auto' }}
          onClick={navigateToCreate}
        >
          {t('Добавить расписание')}
        </Button>
      </div>
      <Tabs
        tabPosition="left"
        items={tabItems}
      />
    </div>
  );
};

export default WorkTime;
