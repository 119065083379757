// import cn from 'classnames';

import styles from './RoomLoading.module.scss';
import React from 'react';
import { useRoomConnectionState } from '../../../store/slicers/room';
import { useTranslation } from 'react-i18next';
import { Spin } from 'antd';

const RoomLoading = () => {
  const [t] = useTranslation();
  const roomConnectionState = useRoomConnectionState();

  // initializing 'Начинаю инициализацию комнаты'
  // creatingRoom 'Создаю или получаю данные комнаты и конфиг для подключения к Пиру'
  // startPeerConnection Конфиг получен, создаю пир соединение и формирую оффер
  // signalToStudent Инициализация комнаты прошла успешно, уведомляю студент о готовности к подключению
  // waitStudent Уведомление по сокету отправлено, ждём сигнал к подключению от студента
  // studentConnecting 'Студент прислал ответ и ICE'
  // studentReady 'Студент прислал сигнал о готовности подключиться'
  const states = {
    initializing: {
      loading: true,
      message: t('Начинаю инициализацию комнаты'),
    },
    creatingRoom: {
      loading: true,
      message: t('Получаю секретные данные комнаты'),
    },
    startPeerConnection: {
      loading: true,
      message: t('Проверяю соединение'),
    },
    signalToStudent: {
      loading: true,
      message: t('Проверяю готовность студента'),
    },
    waitStudent: {
      loading: false,
      message: t('Студент уведомлён и скоро подключится'),
    },
    studentConnecting: {
      loading: true,
      message: t('Студент готов подключиться'),
    },
    studentReady: {
      loading: true,
      message: t('Студент подключается'),
    },
  };

  if (roomConnectionState.step) return (
    <div className={styles.container}>
      {
        states[roomConnectionState.step].loading && (
          <div>
            <Spin spinning />
          </div>
        )
      }
      <div>
        {states[roomConnectionState.step].message}
      </div>
    </div>
  );

  return null;
};

export default RoomLoading;
