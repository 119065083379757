import React, { useEffect, useRef } from 'react';
import cn from 'classnames';

import styles from './ChatBoxFileMessage.module.scss';
import ContentLoader from '../../../../components/ContentLoader';
import ChatMessage from '../../../../components/ChatMessage';
import { bytesToMegabytes, formatTime, getFileDropError } from '../../../../utils';
import { Button, Col, Input, message, Row } from 'antd';
import { useTranslation } from 'react-i18next';
import {
  useLazyGetChatFileMessagesQuery,
  useReadAllFileMessagesMutation,
  useSendFileMutation
} from '../../../../store/apis/chats';
import {
  resetUnreadFileMessages,
  resetUnreadTextMessages,
  setBunchFileMessages, setFileMessage,
  useActiveChat,
  useChatFileMessages
} from '../../../../store/slicers/chat';
import { useDispatch } from 'react-redux';
import { useDropzone } from 'react-dropzone';

const ChatBoxFileMessage = () => {
  const [t, i18n] = useTranslation();
  const [activeChatId, activeChat] = useActiveChat();
  const dispatch = useDispatch();
  const boxRef = useRef(null);

  const [getFileMessages, { isLoading, isFetching }] = useLazyGetChatFileMessagesQuery();
  const [sendFileMessage, { isLoading: isSendLoading, isFetching: isSendFetching }] = useSendFileMutation();
  const [readAllFileMessages] = useReadAllFileMessagesMutation();

  const messages = useChatFileMessages(activeChatId);

  useEffect(() => {
    if (!activeChatId) return;
    const chatMessageQuery = {
      chatId: activeChatId,
      page: -1,
    }
    getFileMessages(chatMessageQuery).unwrap()
      .then((messages) => {
        dispatch(setBunchFileMessages({
          chatId: activeChatId,
          messages: messages.results,
        }));
      })
      .catch((e) => {
        console.log(JSON.stringify(e.data ?? e, 0, 2));
      })
  }, [activeChatId, dispatch, getFileMessages]);

  useEffect(() => {
    if (boxRef.current) boxRef.current.scrollIntoView();
  }, [messages]);

  useEffect(() => {
    if (activeChat?.unreadFileMessagesCount > 0) {
      readAllFileMessages(activeChatId);
      dispatch(resetUnreadFileMessages(activeChatId));
    }
  }, [activeChat, activeChatId, readAllFileMessages, dispatch]);

  const sendChatFile = async (files) => {
    const promises = files.map((file) => {
      return new Promise((resolve, reject) => {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('chatId', activeChatId);
        sendFileMessage(formData)
          .unwrap()
          .then((file) => {
            dispatch(setFileMessage({ chatId: activeChatId, message: file }));
            resolve(file);
          })
          .catch(reject)
      });
    });

    try {
      await Promise.all(promises);
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  };

  const rejectFile = (files) => {
    files.forEach(({ file, errors }) => {
      const { name, size } = file;
      errors.forEach(({ code }) => {
        message.error(`${getFileDropError(code)} - ${name} (${bytesToMegabytes(size)}мб)`);
      });
    });
  };

  const {
    getRootProps,
    getInputProps,
    open,
    isDragAccept,
    isDragReject,
  } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDropRejected: rejectFile,
    onDropAccepted: sendChatFile,
  });

  const messageListClasses = cn({
    [styles.messages]: true,
    [styles.messagesAccepted]: isDragAccept,
    [styles.messagesRejected]: isDragReject,
  })

  return (
    <div {...getRootProps({ className: messageListClasses })}>
      {isDragAccept && <div className={styles.messagesHint}>{t('Отпустите чтобы отправить файл')}</div>}
      <div className={styles.messagesList}>
        <input {...getInputProps()} />
        {
          (isLoading || isFetching) && (<ContentLoader />)
        }

        <ChatMessage>
          {
            messages?.map(({ id, isMyMessage, file, sendTime,  }) => (
              <ChatMessage.MessageItem
                key={id}
                text={
                  <a href={file.url} download target="_blank" rel="noopener noreferrer">{file.name}</a>
                }
                fullName={activeChat.user.fullName?.[i18n.language] ?? t('Без имени')}
                isMyMessage={isMyMessage}
                sendTime={sendTime}
              />
            ))
          }
        </ChatMessage>
        <div style={{ float: 'left', clear: 'both' }} ref={boxRef} />
      </div>

      <div className={styles.messagesForm}>
        <Row align="bottom" gutter={16} justify="end">
          <Col>
            <Button
             type="primary"
             htmlType="button"
             onClick={open}
             loading={isSendLoading || isSendFetching}
            >
              {t('Выбрать файл для отправки')}
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ChatBoxFileMessage;
