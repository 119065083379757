import React from 'react';
import cn from 'classnames';

import styles from './ContentEmpty.module.scss';
import { Empty } from 'antd';

const ContentEmpty = ({ label, action, icon = '🙅‍♀️' }) => {
  return (
    <div className={styles.empty}>
      <div className={styles.emptyIcon}>{icon}</div>
      <div className={styles.emptyLabel}>{label}</div>
      {
        action && (
          <div className={styles.emptyAction}>
            {action}
          </div>
        )
      }
    </div>
  );
};

export default ContentEmpty;
