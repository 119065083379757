import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRejectRescheduleMutation } from '../../store/apis/schedule';
import { Button, message, Popconfirm } from 'antd';

const RejectReschedule = ({ id }) => {
  const [t] = useTranslation();

  const [rejectReschedule, { isLoading, isFetching }] = useRejectRescheduleMutation();

  const handleOk = useCallback(async () => {
    try {
      await rejectReschedule(id).unwrap();
      message.success(t('Вы отказали в переносе - Урок отменен'));
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [rejectReschedule, id, t]);

  return (
    <Popconfirm
      title={t('Вы уверены?')}
      description={t('Урок будет отменен')}
      okText={t('Да, отменить урок')}
      cancelText={t('Нет')}
      onConfirm={handleOk}
      okButtonProps={{
        loading: isLoading || isFetching,
        disabled: !id,
      }}
    >
      <Button
        htmlType="button"
        size="small"
        type="text"
        danger
      >
        {t('Отклонить')}
      </Button>
    </Popconfirm>
  );
};

export default RejectReschedule;
