import React, { useCallback } from 'react';
import { Button, Card, Col, Row, Skeleton, Tooltip, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGetLessonSchedulesQuery } from '../../../store/apis/schedule';
import { prop, uniqBy } from 'ramda';
import { useStartDialogMutation } from '../../../store/apis/chats';
import { useNavigate } from 'react-router';
import routes from '../../../router/routes';
import { setActiveChat } from '../../../store/slicers/chat';
import { useDispatch } from 'react-redux';
import StartChat from '../../../containers/StartChat';
import ContentError from '../../../components/ContentError';

const { Title } = Typography;

const Students = () => {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const {
    data: lessonSchedules,
    isLoading,
    isFetching,
    error,
  } = useGetLessonSchedulesQuery({ page: -1 });

  const [startDialog] = useStartDialogMutation();

  const opeStudentChat = useCallback((studentId) => async () => {
    try {
      const { id } = await startDialog({ userId: studentId, userType: 'student' }).unwrap();

      dispatch(setActiveChat(id));
      navigate(routes.chats());
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [dispatch, navigate, startDialog]);

  if (isLoading || isFetching) return <Skeleton loading />
  if (error) return <ContentError error={error} />

  const uniqStudents = uniqBy(prop('student'), lessonSchedules.results);


  return (
    <div>
      <Title level={3}>{t('Студенты')}</Title>
      <Row gutter={16}>
        {
          uniqStudents.map(({ student }) => (
            <Col lg={6} key={student.id}>
              <Card
                cover={<img src={student.photoUrl} alt=""/>}
                actions={[
                  <StartChat
                    trigger={
                      <Tooltip title={t('Написать студенту')}>
                        <Button type="button">
                          💬
                        </Button>
                      </Tooltip>
                    }
                    userId={student.id}
                  />
                ]}
              >
                <Title level={3} style={{ textAlign: 'center' }}>
                  {student.fullName?.[i18n.language]}
                </Title>
              </Card>
            </Col>
          ))
        }
      </Row>
    </div>
  );
};

export default Students;
