import React, { useCallback } from 'react';
import routes from '../../router/routes';
import queryString from 'query-string';
import { useNavigate } from 'react-router';
import { Button, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

const ReconnectToLesson = ({
  roomId,
  studentId,
  show,
}) => {
  const [t] = useTranslation();
  const navigate = useNavigate();

  const reconnectLesson = useCallback(async () => {

    navigate({
      pathname: routes.room(),
      search: queryString.stringify({
        roomId, studentId,
      }),
    });
  }, [roomId, studentId, navigate]);

  return show
    ? (
      <Tooltip title={t('Переподключиться к уроку')}>
        <Button type="link" onClick={reconnectLesson}>
          🎥
        </Button>
      </Tooltip>
    )
    : null;
};

export default ReconnectToLesson;
