import React, { useCallback, useState } from 'react';
import { Avatar, Badge, Button, Card, Space } from 'antd';
import { useTranslation } from 'react-i18next';
import ChatBoxTextMessages from '../ChatBoxTextMessages';
import { changeTab, setActiveChat, useActiveChat, useActiveTab } from '../../../../store/slicers/chat';
import ContentEmpty from '../../../../components/ContentEmpty';
import ChatBoxFileMessage from '../ChatBoxFileMessage';
import { useDispatch } from 'react-redux';
import { CloseOutlined } from '@ant-design/icons';

const ChatBox = ({ canClose = true }) => {
  const [t, i18n] = useTranslation();
  const [activeChatId, activeChat] = useActiveChat();
  const tab = useActiveTab();
  const dispatch = useDispatch();

  const tabs = [
    {
      key: 'textMessages',
      tab: (
        <Badge count={activeChat?.unreadTextMessagesCount} size="default" offset={[10, -2]}>
          {t('Чат')}
        </Badge>
      ),
    },
    {
      key: 'fileMessages',
      tab: (
        <Badge count={activeChat?.unreadFileMessagesCount} size="default" offset={[10, -2]}>
          {t('Файлы')}
        </Badge>
      ),
    },
  ];

  const contentList = {
    textMessages: <ChatBoxTextMessages />,
    fileMessages: <ChatBoxFileMessage />,
  };

  const closeChat = useCallback(() => {
    dispatch(setActiveChat(null));
    dispatch(changeTab('textMessages'));
  }, [dispatch]);

  return (
    <div style={{ height: '100%' }}>
      {
        activeChatId
          ? (
            <Card
              title={
                <Space>
                  <Avatar src={activeChat?.user?.photoUrl} size="large" />
                  {activeChat?.user?.fullName?.[i18n.language] ?? t('Без имени')}
                </Space>
              }
              extra={
                canClose && (
                  <Button
                    type="text"
                    icon={<CloseOutlined />}
                    onClick={closeChat}
                  >
                    {t('Закрыть чат')}
                  </Button>
                )
              }
              style={{ height: '100%' }}
              bodyStyle={{ height: 'calc(100% - 110px)' }}
              tabList={tabs}
              activeTabKey={tab}
              onTabChange={(key) => {
                dispatch(changeTab(key));
              }}
            >
              {contentList[tab]}
            </Card>
          ) : (
            <ContentEmpty label={t('Выберите чат из списка')} icon="👈" />
          )
      }
    </div>
  );
};

export default ChatBox;
