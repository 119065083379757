import React, { useCallback } from 'react';
import { Button, Form, Input, message, Modal, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import useToggle from '../../hooks/useToggle';
import { useCancelLessonMutation } from '../../store/apis/schedule';

const CancelLesson = ({
  lessonTime,
  scheduleId,
}) => {
  const [t] = useTranslation();
  const [isOpen, open, close] = useToggle();

  const [form] = Form.useForm();

  const [cancelLesson, { isLoading, isFetching }] = useCancelLessonMutation();

  const closeAndReset = useCallback(() => {
    form.resetFields();
    close();
  }, [close, form]);

  const handleSubmit = useCallback(async () => {
    try {
      const values = await form.validateFields();
      const {
        id,
        ...body
      } = values;

      await cancelLesson({ id, body }).unwrap();
      form.resetFields();
      close();
      message.success(t('Урок отменен Мы уведомим об этом ученика'))
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [cancelLesson, close, form, t]);

  return (
    <>
      <Tooltip title={t('Отменить урок')}>
        <Button type="link" onClick={open}>
          ❌
        </Button>
      </Tooltip>
      <Modal
        open={isOpen}
        title={t('Напишите комментарий к отмене урока')}
        okText={t('Отменить урок')}
        cancelText={t('Закрыть')}
        onCancel={closeAndReset}
        onOk={handleSubmit}
        confirmLoading={isLoading || isFetching}
      >
        <Form
          form={form}
          layout="vertical"
          name="cancel_lesson"
          initialValues={{
            lessonTime,
            id: scheduleId,
          }}
        >
          <Form.Item hidden name="lessonTime" />
          <Form.Item hidden name="id" />
          <Form.Item
            name="comment"
            label={t('Комментарий')}
            rules={[
              { required: true },
            ]}
          >
            <Input.TextArea autoSize={{ minRows: 10 }} />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default CancelLesson;
