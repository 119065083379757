import React, { useEffect } from 'react';
import { Avatar, Badge, Card, List, Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';
import { useLazyGetChatDialogsQuery } from '../../../../store/apis/chats';
import { useDispatch } from 'react-redux';
import { setActiveChat, setInitialDialogs, useChatDialogs } from '../../../../store/slicers/chat';

const Dialogs = () => {
  const [t, i18n] = useTranslation();
  const dispatch = useDispatch();

  const dialogs = useChatDialogs();

  const [getChatDialogs, { isLoading, isFetching }] = useLazyGetChatDialogsQuery({ page: -1 });

  useEffect(() => {
    getChatDialogs().unwrap()
      .then((dialogs) => {
        dispatch(setInitialDialogs(dialogs.results));
      })
      .catch((e) => {
        console.log(JSON.stringify(e.data ?? e, 0, 2));
      });
  }, [dispatch, getChatDialogs]);

  const listData = dialogs.map((chat) => ({
    id: chat.id,
    photoUrl: chat.user?.photoUrl,
    fullName: chat.user?.fullName?.[i18n.language],
    lastMessage: chat?.lastMessage?.text,
    totalUnreadMessages: chat.unreadTextMessagesCount + chat.unreadFileMessagesCount,
  }));

  const openChat = (chatId) => () => {
    dispatch(setActiveChat(chatId));
  };

  return (
    <div style={{ height: '100%' }}>
      <Card style={{ height: '100%', overflow: 'scroll' }} title={t('Активные чаты')}>
        <Skeleton loading={isFetching || isLoading} avatar={{ shape: 'circle' }} active paragraph={false}>
          <List
            itemLayout="horizontal"
            dataSource={listData}
            renderItem={(item) => (
              <List.Item onClick={openChat(item.id)}>
                <List.Item.Meta
                  avatar={<Avatar src={item.photoUrl} />}
                  title={item.fullName || t('Без имени')}
                  description={item.lastMessage}
                />
                <Badge count={item.totalUnreadMessages} />
              </List.Item>
            )}
          />
        </Skeleton>
      </Card>
    </div>
  );
};

export default Dialogs;
