import React, { useContext, useEffect, useState } from 'react';

import styles from './RoomContent.module.scss';
import { Col, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import ChatBox from '../../activities/Chats/ChatBox';
import RoomVideoControls from '../RoomVideoControls';
// import { socket } from '../../../socket';
import NoMicrophoneWarning from '../RoomWarning/NoMicrophoneWarning';
import NoPermissionsWarningModal from '../RoomWarning/NoPermissionsWarningModal';
import UnexpectedBehaviourModal from '../RoomWarning/UnexpectedBehaviourModal';
import RoomLoading from '../RoomLoading';
import SelfVideo from '../SelfVideo';
import { socket } from '../../../socket';

const { Title } = Typography;

const RoomContent = () => {
  const [t] = useTranslation();
  const [isRemoteVideoOn, setIsRemoteVideoOn] = useState(true);


  useEffect(() => {
    const handleRemoteUserToggleVideo = (data) => {
      setIsRemoteVideoOn(data.on);
    };
    socket.on('toggleVideo', handleRemoteUserToggleVideo);

    return () => {
      socket.off('toggleVideo', handleRemoteUserToggleVideo);
    };
  }, []);

  return (
    <Row gutter={16}>
      <Col md={16}>
        <div className={styles.videos} id="video-container">
          <div className={styles.videosHeader}>
            <Title level={3}>{t('Классная комната')}</Title>
          </div>
          <div className={styles.videosContainer}>
            <NoMicrophoneWarning />
            <NoPermissionsWarningModal />
            <UnexpectedBehaviourModal />
            <RoomLoading />
            {
              !isRemoteVideoOn
                ? (
                  <div className={styles.videosRemoteOffMessage}>
                    {t('Пользователь отключил видеокамеру')}
                  </div>
                ) : null
            }
            <video className={styles.videosContainerRemote} id="remote-video-player" autoPlay />
            <SelfVideo />
          </div>
          <RoomVideoControls />
        </div>
      </Col>

      <Col md={8}>
        <div className={styles.videosChat}>
          <ChatBox canClose={false} />
        </div>
      </Col>
    </Row>
  );
};

export default RoomContent;
