// import cn from 'classnames';

import styles from './SelfVideo.module.scss';
import React, { memo, useEffect } from 'react';
import useMedia from '../../../rtc/hooks/useMedia';
import EventType from '../../../rtc/EventType';
import { useMediaState } from '../../../store/slicers/room';
import { Spin } from 'antd';

const SelfVideo = () => {
  const MediaDevice = useMedia();
  const mediaState = useMediaState();

  useEffect(() => {
    const videoPlayer = document.getElementById('self-video-player');
    if (videoPlayer) {
      MediaDevice.start()
        .on(EventType.STREAM, (stream) =>  {
          videoPlayer.srcObject = stream;
        })
    }

    return () => {
      MediaDevice.stop();
      MediaDevice.stopDisplayMedia();
    }
  }, [MediaDevice]);

  return (
    <div className={styles.wrapper}>
      {mediaState.loading && <Spin spinning />}
      <video
        className={styles.video}
        autoPlay
        id="self-video-player"
        muted
        playsInline
      />
    </div>
  );
};

export default memo(SelfVideo);
