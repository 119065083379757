import React from 'react';
import RoomContent from '../../contents/room/RoomContent';
import { Navigate } from 'react-router-dom';
import routes from '../../router/routes';
import useQueryString from '../../hooks/useQueryString';
import usePrepareRoom from '../../rtc/hooks/usePrepareRoom';

const Room = () => {
  const { roomId, studentId } = useQueryString();

  usePrepareRoom();

  if (!roomId || !studentId) return <Navigate to={routes.schedule()} replace={true} />

  return (
    <RoomContent />
  );
};

export default Room;
