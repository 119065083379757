import React, { useCallback, useContext } from 'react';

import styles from './Entry.module.scss';
import { Button, Col, Form, Input, Row, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';
import { useLoginMutation } from '../../store/apis/auth';
import localforage from 'localforage';
import routes from '../../router/routes';
import { socket } from '../../socket';

const Entry = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [login, { isLoading: isLoginLoading }] = useLoginMutation();

  const handleSubmit = useCallback(async (values) => {
    try {
      const credentials = await login(values).unwrap();
      await localforage.setItem('credentials', credentials);
      navigate(routes.welcome());
      socket.connect();
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [login, dispatch, navigate]);

  return (
    <div className={styles.entry}>
      <Row
        justify="center"
        align="middle"
        className={styles.entryRow}
      >
        <Col sm={24} md={12} xl={8}>
          <div className={styles.entryWrapper}>
            <div className={styles.entryLogo}>
              <img src="/images/logo-rus.svg" alt=""  />
            </div>
            <Typography.Title level={4} type="secondary" className={styles.entryTitle}>
              {t('Панель для Преподавателей')}
            </Typography.Title>

            <Form
              name="auth"
              layout="vertical"
              initialValues={{}}
              onFinish={handleSubmit}
              autoComplete="off"
              disabled={isLoginLoading}
            >
              <Form.Item
                label={t('Логин')}
                name="username"
                rules={[
                  {
                    required: true,
                    message: t('Введите логин'),
                  },
                ]}
              >
                <Input />
              </Form.Item>

              <Form.Item
                label={t('Пароль')}
                name="password"
                rules={[
                  {
                    required: true,
                    message: t('Введите пароль'),
                  },
                ]}
              >
                <Input.Password />
              </Form.Item>

              <Form.Item
                wrapperCol={{
                  span: 24,
                }}
              >
                <Button
                  type="primary"
                  htmlType="submit"
                  block
                  loading={isLoginLoading}
                >
                  {t('Войти')}
                </Button>
              </Form.Item>
            </Form>
          </div>
        </Col>
      </Row>
    </div>
  );
};

export default Entry;
