import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './ErrorPage.module.scss';
import { useNavigate, useRouteError } from 'react-router';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';
import routes from '../../router/routes';

const ErrorPage = () => {
  const [t] = useTranslation();
  const error = useRouteError();
  const navigate = useNavigate();

  const handleBackToMain = useCallback((e) => {
    e.preventDefault();

    navigate(routes.welcome());
  }, [navigate]);

  return (
    <div className={styles.error}>
      <div className={styles.errorIcon}>🤕</div>
      <div className={styles.errorTitle}>{error.status}</div>
      <div className={styles.errorSubtitle}>{error.statusText}</div>
      <Button
        type="primary"
        size="large"
        onClick={handleBackToMain}
      >
        {t('Вернуться на главную')}
      </Button>
    </div>
  );
};

export default ErrorPage;
