import axios from 'axios';
import apiRoutes, { baseUrl } from './apiRoutes';
import localforage from 'localforage';
import { identity } from 'ramda';
import i18n from './localization/i18n';
import { socket } from './socket';
import { message } from 'antd';
import router from './router';

const httpClient = axios.create({
  baseURL: baseUrl,
});

httpClient.interceptors.request.use(async (config) => {
  const credentials = await localforage.getItem('credentials');
  if (credentials && credentials.accessToken) {
    return {
      ...config,
      headers: {
        ...config.headers,
        'Accept-Language': i18n.language,
        Authorization: `Bearer ${credentials.accessToken}`
      },
    };
  }

  return {
    ...config,
    headers: {
      ...config.headers,
      'Accept-Language': i18n.language,
    },
  };
});

let isRefreshing = false;
let refreshSubscribers = [];

const subscribeTokenRefresh = (cb) => {
  refreshSubscribers.push(cb);
};

const onRefreshed = (newCredentials) => {
  refreshSubscribers.map(cb => cb(newCredentials.accessToken));
  refreshSubscribers = [];
};

httpClient.interceptors.response.use(identity, async (error) => {
  if (!error.response || error.response.status !== 401) return Promise.reject(error);

  const credentials = await localforage.getItem('credentials');

  if (!credentials || !credentials.refreshToken) return Promise.reject(error);
  const originalRequest = error.config;

  if (!isRefreshing && localStorage.isRefreshing !== 'yes') {
    isRefreshing = true;
    localStorage.setItem('isRefreshing', 'yes');
    axios.post(baseUrl + apiRoutes.refreshToken(), { refreshToken: credentials.refreshToken })
      .then(newCredentials => {
        isRefreshing = false;
        localStorage.setItem('isRefreshing', 'no');
        localforage.setItem('credentials', newCredentials.data)
          .then(() => {
            socket.connect();
          });
        onRefreshed(newCredentials.data);
      })
      .catch((err) => {
        localforage.clear();
        isRefreshing = false;
        localStorage.setItem('isRefreshing', 'no');
        router.navigate('/');
        message.error('Ваша сессия истекла. Вам нужно войти заново');
      });
  }

  const retryOrigReq = new Promise((resolve, reject) => {
    subscribeTokenRefresh(token => {
      // replace the expired token and retry
      originalRequest.headers['Authorization'] = 'Bearer ' + token;
      resolve(axios(originalRequest));
    });
  });
  return retryOrigReq;
  //
  // try {
  //   const newCredentials = await axios.post(baseUrl + apiRoutes.refreshToken(), { refreshToken: credentials.refreshToken });
  //   await localforage.setItem('credentials', newCredentials.data);
  //   socket.connect();
  //   return await httpClient(originalRequest);
  // } catch (e) {
  //   await localforage.clear();
  //   console.error(e);
  //   return Promise.reject(error);
  // }
});

export default httpClient;
