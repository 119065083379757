import React, { useMemo } from 'react';
import cn from 'classnames';

import styles from './LabelData.module.scss';
import { Typography } from 'antd';

const { Title, Text } = Typography;

/**
 *
 * @param label
 * @param data
 * @param extra
 * @param {('vertical' | 'horizontal')} layout
 * @returns {JSX.Element}
 * @constructor
 */
const LabelData = ({
  label,
  data,
  extra,
  layout = 'vertical',
}) => {

  const wrapperClasses = useMemo(() => cn({
    [styles.wrapper]: true,
    [styles.wrapperHorizontal]: layout === 'horizontal',
  }), [layout]);

  return (
    <div className={wrapperClasses}>
      <div className={styles.wrapperLabel}>
        <Text type="secondary">
          {label}
        </Text>
        {
          extra && (
            <div className={styles.extraLabel}>
              {extra}
            </div>
          )
        }
      </div>
      <div className={styles.wrapperData}>
        <Title level={5} style={{ marginBottom: 0 }}>
          {
            data || '-'
          }
        </Title>
      </div>
    </div>
  );
};

export default LabelData;
