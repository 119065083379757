import styles from './RoomWarning.module.scss';
import { FiAlertTriangle } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import useMedia from '../../../rtc/hooks/useMedia';
import { useEffect, useState } from 'react';
import EventType from '../../../rtc/EventType';
import ErrorMessage from '../../../rtc/ErrorMessage';
import { Button } from 'antd';

const NoPermissionsWarningModal = () => {
  const [open, setOpen] = useState(false);
  const [t] = useTranslation();
  const MediaDevice = useMedia();

  useEffect(() => {
    MediaDevice.on(EventType.ERROR, (error) => {
      if (error.name && error.name === ErrorMessage.CAMERA_AND_MIC_PERMISSION_DENIED) {
        setOpen(true);
      }
    })
  }, [MediaDevice]);

  if (!open) return null;

  return (
    <div className={styles.warning}>
      <div className={styles.warningWrapper}>
        <div className={styles.warningIcon}>
          <FiAlertTriangle/>
        </div>
        <h4 className={styles.warningTitle}>{t('Похоже на то, что Вы запретили использовать камеру или микрофон')}</h4>
        <p className={styles.warningText}>{t('Для успешного подключения к классной комнате, Вы должны дать разрешение сайту использовать камеру и микрофон')}</p>
        <p className={styles.warningText}>{t('Дать разрешение Вы можете в настройках браузера -> Безопасность -> Настройка сайтов')}</p>
        <p className={styles.warningText}>{t('Если Вы дали разрешение, то можете обновить страницу и проверить подключение, а это сообщение не должно больше появляться')}</p>
        <div className={styles.warningActions}>
          <Button
            onClick={() => {
              window.location.reload();
            }}
            type="primary"
          >
            {t('Обновить страницу')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NoPermissionsWarningModal;
