import i18n from './localization/i18n';

export const weekDayNames = [
  null,
  i18n.t('Понедельник'),
  i18n.t('Вторник'),
  i18n.t('Среда'),
  i18n.t('Четверг'),
  i18n.t('Пятница'),
  i18n.t('Суббота'),
  i18n.t('Воскресение'),
];

export const errorMessages = {
  NO_DEVICES: i18n.t('Вам нужна веб камера и микрофон, чтобы подключиться к уроку'),
  CANNOT_GET_USER_MEDIA: i18n.t('Не удалось получить доступ к веб камере или микрофону'),
};
