import React from 'react';

import { Col, Row } from 'antd';
import Dialogs from './Dialogs';
import ChatBox from './ChatBox';

const MainContainer = () => {

  return (
    <Row style={{ height: '79vh' }} gutter={16}>
      <Col md={8} style={{ height: '100%' }}>
        <Dialogs />
      </Col>
      <Col md={16} style={{ height: '100%' }}>
        <ChatBox />
      </Col>
    </Row>
  );
};

export default MainContainer;
