import { combineReducers, configureStore } from '@reduxjs/toolkit';

import { rtkQueryErrorLogger } from './middlewares/handleError';
// slicers
import { user as userSlice } from './slicers/user';
import { chat as chatSlice } from './slicers/chat';
import { room as roomSlice } from './slicers/room';

// apis
import { authApi } from './apis/auth';
import { mainApi } from './apis/mainApi';

const rootReducer = combineReducers({
  user: userSlice.reducer,
  chat: chatSlice.reducer,
  room: roomSlice.reducer,
  [authApi.reducerPath]: authApi.reducer,
  [mainApi.reducerPath]: mainApi.reducer,
});

export default configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  })
    .concat(rtkQueryErrorLogger)
    .concat(authApi.middleware)
    .concat(mainApi.middleware),
});
