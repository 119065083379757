import { joinExisting } from './utils';

// export const baseUrl = 'https://api.fiveplus2.endpoint.uz/api/v1';
export const baseUrl = process.env.REACT_APP_HOST;

export default {
  login: () => joinExisting(['/account/tutor/login']),
  refreshToken: () => joinExisting(['/account/public/tokens/refresh']),
  me: () => joinExisting(['/search/tutor/me/profile']),

  workTime: (id) => joinExisting(['/learning/tutor/work-schedules', id]),
  checkNewWorkTimeSchedules: () => joinExisting(['/learning/tutor/work-schedules/outside-lesson-schedules']),
  deleteSchedules: () => joinExisting(['/learning/tutor/lesson-schedules/many']),

  schedule: (id) => joinExisting(['/learning/tutor/lesson-schedules/lessons', id]),
  lessons: () => joinExisting(['/learning/tutor/lessons']),
  lessonSchedules: () => joinExisting(['/learning/tutor/lesson-schedules']),
  cancelLesson: (scheduleId) => joinExisting(['/learning/tutor/lesson-schedules', scheduleId, 'cancel-lesson']),
  rescheduleLesson: (scheduleId) => joinExisting(['/learning/tutor/lesson-schedules', scheduleId, 'request-transfer-lesson']),
  rescheduledLessonList: () => joinExisting(['/learning/tutor/scheduled-lesson-transfer-requests']),
  approveReschedule: (id) => joinExisting(['/learning/tutor/scheduled-lesson-transfer-requests', id, 'approve']),
  rejectReschedule: (id) => joinExisting(['/learning/tutor/scheduled-lesson-transfer-requests', id, 'reject']),
  startLesson: (id) => joinExisting(['/learning/tutor/lesson-schedules', id, 'start-lesson']),
  finishLesson: (id) => joinExisting(['/learning/tutor/lessons', id, 'finish']),


  // chats
  dialogs: (id) => joinExisting(['chat/chats', id]),
  chatMessages: (id) => joinExisting(['chat/text-messages', id]),
  chatFiles: (id) => joinExisting(['chat/file-messages', id]),
  readMessage: (id) => joinExisting(['chat/messages/mark-as-read', id]),
  readAllFiles: (id) => joinExisting(['chat/chats', id, 'file-messages/mark-as-read']),
  readAllMessages: (id) => joinExisting(['chat/chats', id, 'text-messages/mark-as-read']),

  // room and lesson
  createRoom: (id) => joinExisting(['room/tutor/general-rooms', id]),
  addIce: (id) => joinExisting(['room/tutor/general-rooms', id, 'ice']),
  setOffer: (id) => joinExisting(['room/tutor/general-rooms', id, 'sdp']),

  // statistics
  statistics: () => joinExisting(['report/tutor/statistics']),
  withdrawals: () => joinExisting(['learning/tutor/funds-withdrawals']),
  paychecks: (id) => joinExisting(['learning/tutor/paychecks', id]),

  // notifications
  notifications: () => joinExisting(['system-notification/notifications']),
  readOneNotification: (id) => joinExisting(['system-notification/notifications', id, 'mark-as-read']),
  readAllNotification: () => joinExisting(['system-notification/notifications/mark-as-read']),

  // service config
  serviceConfig: () => joinExisting(['learning/public/service-configs']),
};
