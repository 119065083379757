import httpClient from '../httpClient';

export const baseQuery = async (args, api, extra) => {
  const userTimezone = api.getState().user.timezone;
  const axiosConfig = {
    ...args,
    data: args.body,
    headers: {
      ...args.headers,
      'X-Time-Span-TZ': userTimezone,
    },
  };


  try {
    const response = await httpClient(axiosConfig);
    return { data: response.data };
  } catch (e) {
    if (e.response) return { error: e.response };
    const error = {
      status: 'CORS',
      error: e.message,
    }
    return { error };
  }
};

export const invalidateOn = ({ success = [], error = [] }) => {
  return (result, err) => err ? error : success;
};
