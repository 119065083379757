import React, { useCallback } from 'react';
import { useStartDialogMutation } from '../../store/apis/chats';
import { setActiveChat } from '../../store/slicers/chat';
import routes from '../../router/routes';
import { useNavigate } from 'react-router';
import { useDispatch } from 'react-redux';

const StartChat = ({
  trigger,
  userId,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [startDialog] = useStartDialogMutation();

  const openStudentChat = useCallback((studentId) => async () => {
    try {
      const { id } = await startDialog({ userId: studentId, userType: 'student' }).unwrap();

      dispatch(setActiveChat(id));
      navigate(routes.chats());
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [dispatch, navigate, startDialog]);

  const Component = React.cloneElement(
    trigger,
    {
      onClick: openStudentChat(userId),
    }
  );

  return Component;
};

export default StartChat;
