import React, { useCallback, useMemo } from 'react';

import styles from './ContentError.module.scss';
import { useTranslation } from 'react-i18next';

const ContentError = ({ error }) => {
  const [t] = useTranslation();
  const refreshPage = useCallback((e) => {
    e.preventDefault();
    window.location.reload();
  }, []);

  console.log('================= ERROR =====================');
  console.log(JSON.stringify(error, 0, 2));

  const errNames = useMemo(() => ({
    CORS: t('Кажется у Вас отсутствует интернет соединение'),
  }), [t]);

  return (
    <div className={styles.error}>
      <div className={styles.errorIcon}>🤕</div>
      <div className={styles.errorTitle}>{t('Ой')}</div>
      <div className={styles.errorSubtitle}>
        {errNames[error.status] || t('Кажется что-то пошло не так')}
      </div>
      {
        (error?.name || error?.message)
          && (
          <div className={styles.errorInfo}>
            {[error.name, error.message].join(', ')}
          </div>
        )
      }
      <div>
        {t('Попробуйте')}
        {' '}
        <a href="#" onClick={refreshPage}>
          {t('перезагрузить страницу')}
        </a>
      </div>
    </div>
  );
};

export default ContentError;
