import { useContext, useEffect } from 'react';
import { socket } from '../socket';
import { useDispatch } from 'react-redux';
import useSound from 'use-sound';

import chatSound from '../sounds/chat-sound.wav';
import {
  incrementUnreadFileMessages,
  incrementUnreadTextMessages,
  setFileMessage,
  setTextMessage,
  useActiveChat,
  useActiveTab
} from '../store/slicers/chat';
import { useReadAllFileMessagesMutation, useReadAllTextMessagesMutation } from '../store/apis/chats';
import { mainApi } from '../store/apis/mainApi';
import { useLazyGetMeQuery } from '../store/apis/auth';

export default () => {
  const dispatch = useDispatch();
  const [activeChatId] = useActiveChat();
  const tab = useActiveTab();
  const [play] = useSound(chatSound);

  const [readAllMessages] = useReadAllTextMessagesMutation();
  const [readAllFiles] = useReadAllFileMessagesMutation();
  const [getMe] = useLazyGetMeQuery();


  useEffect(() => {
    // socket.connect();
    const onTextMessage = (message) => {
      const { chatId } = message;
      dispatch(setTextMessage({ chatId, message }));
      if (activeChatId === chatId && tab === 'textMessages') {
        readAllMessages(chatId);
        return;
      }
      dispatch(incrementUnreadTextMessages(chatId));
      play();
    };

    const onFileMessage = (message) => {
      const { chatId } = message;
      dispatch(setFileMessage({ chatId, message }));
      if (activeChatId === chatId && tab === 'fileMessages') {
        readAllFiles(chatId);
        return;
      }
      dispatch(incrementUnreadFileMessages(chatId));
      play();
    };

    const onSystemNotification = (notification) => {
      dispatch(mainApi.util.invalidateTags(['NOTIFICATIONS']))
    };

    socket.on('textMessage', onTextMessage);
    socket.on('fileMessage', onFileMessage);
    socket.on('SystemNotification', onSystemNotification);

    const handleConnectionError = async (err) => {
      console.log('ОШИБКА ПОДКЛЮЧЕНИЯ СОКЕТА');
      await getMe();
      setTimeout(() => {
        console.log('try to reconnect to socket')
        socket.disconnect();
        socket.connect();
      }, 100)
    };

    const handleConnect = () => {
      console.log('socket connected');
    };

    const handleDisconnect = () => {
      console.log('socket disconnected');
    };

    socket.on('connect_error', handleConnectionError);
    socket.on('connection_error', (err) => console.log(err));
    socket.on('connect', handleConnect);
    socket.on('disconnect', handleDisconnect);

    return () => {
      socket.off('textMessage', onTextMessage);
      socket.off('fileMessage', onFileMessage);
      socket.off('SystemNotification', onSystemNotification);
      socket.off('connect_error', handleConnectionError);
      socket.off('connect', handleConnect);
      socket.off('disconnect', handleDisconnect);
    }
  }, [
    activeChatId,
    play,
    tab,
    dispatch,
    readAllMessages,
    readAllFiles,
  ]);

  return socket;
};
