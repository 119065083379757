import { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import useQueryString from '../../hooks/useQueryString';
import { useStartDialogMutation } from '../../store/apis/chats';
import useSockets from '../../hooks/useSockets';
import usePeerConnection from './usePeerConnection';
import useMedia from './useMedia';
import { setActiveChat, setInitialDialogs } from '../../store/slicers/chat';
import { useCreateRoomMutation } from '../../store/apis/room';
import { setConnectionPreparing, setUnexpectedBehaviour } from '../../store/slicers/room';
import EventType from '../EventType';

const usePrepareRoom = () => {
  const { roomId, studentId } = useQueryString();
  const dispatch = useDispatch();
  const [hasPeerConnection, setHasPeerConnection] = useState(false);
  const [hasLocalDescription, setHasLocalDescription] = useState(false);
  const [hasLocalIceCandidates, setHasLocalIceCandidates] = useState(false);

  const PeerConnection = usePeerConnection();
  const MediaDevice = useMedia();

  const socket = useSockets();

  const [startDialog] = useStartDialogMutation();
  const [createOrGetRoom] = useCreateRoomMutation();

  const initRoom = () => {

    // console.log('Начинаю инициализацию комнаты');
    dispatch(setConnectionPreparing('initializing'));
    //
    if (!MediaDevice.stream) {
      // console.log('Не смог получить MediaDevice.stream');
      dispatch(setUnexpectedBehaviour({
        show: true,
        cause: 'NO_MEDIA_DEVICE_STREAM',
      }));
      dispatch(setConnectionPreparing(null));
      return Promise.resolve();
    }

    PeerConnection.on(EventType.REMOTE_STREAM, (stream) => {
      document.getElementById('remote-video-player').srcObject = stream;
      dispatch(setConnectionPreparing(null));
    });
    PeerConnection.on(EventType.CREATED_PEER_CONNECTION, () => {
      setHasPeerConnection(true);
    });
    PeerConnection.on(EventType.ICE_CANDIDATES_SETTED, () => {
      setHasLocalIceCandidates(true);
    });
    PeerConnection.on(EventType.LOCAL_DESCRIPTION_SETTED, () => {
      setHasLocalDescription(true);
    });
    PeerConnection.on(EventType.ERROR, (error) => {
      console.log(error.message);
      dispatch(setUnexpectedBehaviour({
        show: true,
        cause: error.message,
      }));
    });

    dispatch(setConnectionPreparing('creatingRoom'));
    // console.log('Создаю или получаю данные комнаты и конфиг для подключения к Пиру');
    return createOrGetRoom({ roomId, studentId }).unwrap()
      .then((cfg) => {
        dispatch(setConnectionPreparing('startPeerConnection'));
        // console.log('Конфиг получен, создаю пир соединение и формирую оффер');
        PeerConnection
          .startPeerConnection(cfg, MediaDevice.stream)
          .createOffer()
        // console.log('Пир коннекшен установлен и создан оффер');
      })
      .catch((err) => {
        // console.log('Не могу создать или получить данные комнаты');
        dispatch(setUnexpectedBehaviour({
          show: true,
          cause: 'CANNOT_CREATE_ROOM',
        }));
      })
  };

  const emitOfferAndIces = () => {
    const localOffer = PeerConnection.localOffer;
    const localIces = PeerConnection.localIceCandidates;

    socket.emit('directSend', {
      userType: 'student',
      userId: studentId,
      type: 'offerAndIces',
      data: {
        offer: localOffer,
        ices: localIces,
      },
    });
  };

  // Эффект открывает чат с преподавателем
  useEffect(() => {
    if (socket.connected) {
      startDialog({ userId: studentId, userType: 'student' }).unwrap()
        .then((chat) => {
          dispatch(setInitialDialogs([chat]));
          dispatch(setActiveChat(chat.id));
        })
        .catch((e) => {
          console.log(JSON.stringify(e.data ?? e, 0, 2));
        })
      return;
    }
    setTimeout(() => {
      socket.connect();
      startDialog({ userId: studentId, userType: 'student' }).unwrap()
        .then((chat) => {
          dispatch(setInitialDialogs([chat]));
          dispatch(setActiveChat(chat.id));
        })
        .catch((e) => {
          console.log(JSON.stringify(e.data ?? e, 0, 2));
        })

    }, 500);

    return () => {
      dispatch(setActiveChat(null));
    }
  }, []);

  useEffect(() => {
    if (
      hasLocalIceCandidates
        && hasLocalDescription
        && hasPeerConnection
    ) {
      emitOfferAndIces()
    }
  }, [hasLocalIceCandidates, hasLocalDescription, hasPeerConnection]);

  // Создаёт пир соединение
  useEffect(() => {
    dispatch(setConnectionPreparing('initializing'));
    MediaDevice.on(EventType.STREAM, (_, state) => {
      if (state === 'new') {
        // console.log('Ждём пока сокет законнектиться. Это костыль, непонятно почему сокет фейлится')
        createOrGetRoom({ roomId, studentId })
          .then(() => {
            setTimeout(() => {
              dispatch(setConnectionPreparing('signalToStudent'));
              // console.log('Инициализация комнаты прошла успешно, уведомляю студент о готовности к подключению');

              socket.emit('directSend', {
                userType: 'student',
                userId: studentId,
                type: 'readyToConnect',
                data: 'ready',
              });

              dispatch(setConnectionPreparing('waitStudent'));
              // console.log('Уведомление по сокету отправлено, ждём сигнал к подключению от студента');
            }, 3000);
          })
          .catch((err) => {
            dispatch(setUnexpectedBehaviour({
              show: true,
              cause: 'CANNOT_CREATE_ROOM',
            }));
          })
      }
    })
  }, []);

  // Устанавливает обработчики сокета
  useEffect(() => {
    const onStudentWaitSignal = ({ id }) => {
      if (String(id) !== String(roomId)) {
        console.error(`ID комнаты не совпадают! Студент ждёт сигнал для комнаты ${id}. А Вы в комнате ${roomId}`);
        return;
      }

      dispatch(setConnectionPreparing('studentReady'));
      // console.log('Студент прислал сигнал о готовности подключиться');
      if (PeerConnection.peerConnection) {
        setHasPeerConnection(false);
        setHasLocalDescription(false);
        setHasLocalIceCandidates(false)
        PeerConnection.closePeerConnection();
      }
      setTimeout(initRoom, 1000);
      // initRoom();
    };

    const onOfferAndIces = (data) => {
      // console.log('Студент прислал ответ и ICE');
      dispatch(setConnectionPreparing('studentConnecting'));
      const { offer, ices } = data;

      // тут валится ошибка иногда, нужно сделать перезагрузку страницы
      PeerConnection.setRemoteDescription(offer)
        .on(EventType.REMOTE_DESCRIPTION, () => {
          // dispatch(setConnectionPreparing('studentConnecting'));
          // console.log('Remote description установлен. Вызван хендлер');

          // console.log('Создаю промисы для установки ICE кандидатов');
          const addIceCandidatesPromises = ices.map((candidate) => {
            return new Promise((resolve) => {
              return PeerConnection.setRemoteIceCandidate(candidate)
                .then(resolve)
                .catch((err) => {
                  // console.log('Не получилось установить один ICE по причине:');
                  // console.log(err);
                  resolve();

                });
            })
          });

          // console.log('Устанавливаю удаленные ICE кандидаты');
          Promise.all(addIceCandidatesPromises)
            .then(() => {
              // console.log('Кандидаты установлены');
              PeerConnection.emit(EventType.REMOTE_ICE_CANDIDATE)
            })
        })
    };

    socket.on('roomStudentWait', onStudentWaitSignal);
    socket.on('offerAndIces', onOfferAndIces);
    return () => {
      socket.off('roomStudentWait', onStudentWaitSignal);
      socket.off('offerAndIces', onOfferAndIces);
    }
  }, []);

  useEffect(() => {
    return () => {
      // console.log('Пир коннекшен закрывается');
      PeerConnection.closePeerConnection();
      dispatch(setConnectionPreparing(null));
      dispatch(setUnexpectedBehaviour({
        show: false,
        cause: null,
      }));
    }
  }, []);

};

export default usePrepareRoom;
