import { filter, identity, join, pipe, reverse } from 'ramda';
import i18n from './localization/i18n';
import localforage from 'localforage';
import dayjs from 'dayjs';
import { ErrorCode } from 'react-dropzone';

export const dictionaryToSelect = (dict) => {
  if (!dict) return [];
  return dict.map((d) => ({
    value: d.id,
    label: d.name[i18n.language],
  }));
};

export const getCredentials = async () => {
  return await localforage.getItem('credentials');
};

export const removeAccessToken = async () => {
  const oldCred = await localforage.getItem('credentials');
  await localforage.setItem('credentials', { ...oldCred, accessToken: '2' });
}

export const removeCredentials = async () => {
  await localforage.setItem('credentials', { refreshToken: '1', accessToken: '2' });
}


export const formatDate = (date, withTime) => {
  if (!date) return null;
  const dateObj = dayjs(date);
  if (!dateObj.isValid()) return null;
  return withTime
    ? dateObj.format('DD.MM.YYYY (HH:mm)')
    : dateObj.format('DD.MM.YYYY');
};

export const formatTime = (date) => {
  if (!date) return null;
  const dateObj = dayjs(date);
  if (!dateObj.isValid()) return null;
  return dateObj.format('HH:mm');
};


export const toSom = (string) => {
  const num = Number(string);
  if (!string) return string;
  if (Number.isNaN(num)) return string;
  return String((num / 100).toFixed(2));
};

export const getTimeStringByHourId = (hourId) => {
  return hourId <= 9 ? `0${hourId}:00` : `${hourId}:00`
};

const formatter = new Intl.NumberFormat('ru-RU');

export const formatNumber = (number, appendix = null, isTiin = false) => {
  if (number === 0) return String(number);
  if (!number || Number.isNaN(number)) {
    return null;
  }
  if (isTiin) {
    const formattedNum = formatter.format(toSom(number));
    return appendix ? `${formattedNum} ${appendix}` : formattedNum;
  }
  const formattedNum = formatter.format(number);
  return appendix ? `${formattedNum} ${appendix}` : formattedNum;
};

export const joinExisting = (arr, separator = '/') => pipe(
  filter(identity),
  join(separator),
)(arr);

export const normFile = (e) => {
  const { file: { response, status } } = e;

  if (!response || !response.path) return;

  if (status === 'removed') return;

  return response.path;
};

export const getDateDiapasonLabel = (startDate, endDate) => {
  const formatString = 'DD.MM.YYYY';
  if (!startDate && !endDate) return '';

  if (!startDate) {
    const endDateObj = dayjs(endDate);
    if (!endDateObj.isValid()) return '';
    return i18n.t('по {{endDate}}', { endDate: endDateObj.format(formatString) });
  };
  if (!endDate) {
    const startDateObj = dayjs(startDate);
    if (!startDateObj.isValid()) return '';
    return i18n.t('с {{startDate}}', { startDate: startDateObj.format(formatString) });
  }

  const startDateObj = dayjs(startDate);
  const endDateObj = dayjs(endDate);
  if (!startDateObj.isValid() || !endDateObj.isValid()) return '';

  return i18n.t('с {{startDate}} по {{endDate}}', {
    startDate: startDateObj.format(formatString),
    endDate: endDateObj.format(formatString),
  });
};

export const reverseIfExists = (value) => value ? reverse(value) : null;

export const getFileDropError = (code) => {
  const fileDropErrorMessages = {
    [ErrorCode.FileTooLarge]: i18n.t('Размер файла превышает допустимое значение'),
    [ErrorCode.FileInvalidType]: i18n.t('Нельзя выбрать данный тип файла'),
    [ErrorCode.TooManyFiles]: i18n.t('Нужно выбрать только один файл'),
    [ErrorCode.FileTooSmall]: i18n.t('Размер файла слишком мал'),
  };

  return fileDropErrorMessages[code];
};

export const bytesToMegabytes = (amount) => {
  const parseAmount = Number(amount);
  if (Number.isNaN(parseAmount)) return null;
  const mbAmount = amount / 1048576;
  return Math.round(mbAmount * 10) / 10;
};
