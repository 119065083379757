import Emitter from './Emitter';
import EventType from './EventType';
import { createContext } from 'react';

class PeerConnection extends Emitter {
  peerConnection = null;
  remoteDescription = null;
  remoteIceCandidates = [];

  localOffer = null;
  localIceCandidates = [];

  #getSender(kind) {
    const sender = this.peerConnection
      .getSenders()
      .find((sender) => sender.track.kind === kind);

    return sender;
  }

  #addRemoteIceCandidates(candidate) {
    return this.peerConnection.addIceCandidate(candidate);
  }

  getConnectionState() {
    return {
      peerConnection: Boolean(this.peerConnection),
      remoteDescription: Boolean(this.remoteDescription),
      localOffer: Boolean(this.localOffer),
      localIceCandidates: Boolean(this.localIceCandidates.length),
    };
  }

  startPeerConnection(config, stream) {
    if (this.peerConnection) return this;
    this.peerConnection = new RTCPeerConnection(config);

    this.peerConnection.oniceconnectionstatechange = () => {
      this.emit(EventType.ICE_STATE_CHANGED, this.peerConnection.iceConnectionState);
    }

    this.peerConnection.onconnectionstatechange = () => {
      const connectionState = this.peerConnection.connectionState;

      this.emit(EventType.CONNECTION_STATE_CHANGE, connectionState);
      // if (connectionState === 'failed') {
      //   this.closePeerConnection();
      // }
    }

    this.peerConnection.onicecandidate = ({ candidate }) => {
      if (!candidate) {
        this.emit(EventType.ICE_CANDIDATES_SETTED);
        return;
      }
      this.localIceCandidates.push(candidate);
    };

    this.peerConnection.ontrack = ({ streams }) => {
      this.emit(EventType.REMOTE_STREAM, streams[0]);
    };

    this.on(EventType.REMOTE_DESCRIPTION, () => {
      this.remoteIceCandidates.forEach((candidate) => {
        this.#addRemoteIceCandidates(candidate);
      });
    });

    stream.getTracks().forEach((track) => {
      if (this.#getSender(track.kind)) return;
      this.peerConnection.addTrack(track, stream);
    });
    this.emit(EventType.CREATED_PEER_CONNECTION);
    return this;
  }

  closePeerConnection() {
    if (!this.peerConnection) return this;

    this.peerConnection.restartIce();
    this.peerConnection.close();
    this.remoteIceCandidates = [];
    this.remoteDescription = null;
    this.peerConnection = null;
    this.remoteDescription = null;
    this.peerConnection = null;
    this.localOffer = null;
    this.localIceCandidates = [];
    this.off();

    return this;
  }

  createOffer() {
    this.peerConnection.createOffer()
      .then((offer) => {
        this.localOffer = offer;
        return this.peerConnection.setLocalDescription(offer);
      })
      .then(() => {
        this.emit(EventType.LOCAL_DESCRIPTION_SETTED);
      })
      .catch((err) => {
        /*
          Ошибки createAnswer:
          NotReadableError
          The identity provider wasn't able to provide an identity assertion.

          OperationError
          Generation of the SDP failed for some reason; this is a general failure catch-all exception.


          Ошибки setLocalDescription все помечены как устаревшие

        */
        this.emit(EventType.ERROR, err);
      })
    return this;
  }

  createAnswer() {
    this.peerConnection.createAnswer()
      .then((offer) => {
        this.emit(EventType.OFFER, offer);
        return this.peerConnection.setLocalDescription(offer);
      })
      .then(() => {
        this.emit(EventType.LOCAL_DESCRIPTION);
      })
      .catch((err) => {
        /*
          Ошибки createAnswer:
          NotReadableError
          The identity provider wasn't able to provide an identity assertion.

          OperationError
          Generation of the SDP failed for some reason; this is a general failure catch-all exception.


          Ошибки setLocalDescription все помечены как устаревшие

        */
        this.emit(EventType.ERROR, err);
      })

    return this;
  }

  setRemoteDescription(sdp) {
    this.remoteDescription = sdp;

    this.peerConnection.setRemoteDescription(sdp)
      .then(() => {
        this.emit(EventType.REMOTE_DESCRIPTION);
      })
      .catch((err) => {
        /* Возможные ошибки:
            InvalidAccessError DOMException
            Returned if the content of the description is invalid.

            InvalidStateError DOMException
            Returned if the RTCPeerConnection is closed, or it's in a state that is not compatible with the specified description's type. For example, this exception is thrown if the type is rollback and the signaling state is one of stable, have-local-pranswer, or have-remote-pranswer because you cannot roll back a connection that's either fully established or is in the final stage of becoming connected.

            OperationError DOMException
            Returned if an error does not match the ones specified here. This includes identity validation errors.

            RTCError DOMException
            Returned with the errorDetail set to sdp-syntax-error if the SDP specified by RTCSessionDescription.sdp is not valid. The error object's sdpLineNumber property indicates the line number within the SDP on which the syntax error was detected.

            TypeError
            Returned if the specified RTCSessionDescriptionInit or RTCSessionDescription object is missing the type property, or no description parameter was provided at all.
        */
        console.log(err);
        this.emit(EventType.ERROR, new Error('REMOTE_DESCRIPTION_FAILED'));
        console.error('Не удалось установить setRemoteDescription');
      })

    return this;
  }

  setRemoteIceCandidate(ice) {
    if (this.peerConnection && this.peerConnection.remoteDescription) {
      return this.#addRemoteIceCandidates(ice);
    }

    this.remoteIceCandidates.push(ice);
    return Promise.resolve();
  }

  replaceTrack(track) {
    if (!this.peerConnection) return;

    const sender = this.#getSender(track.kind);

    if (!sender) return;

    sender.replaceTrack(track)
      .then(() => {
        this.emit(EventType.TRACK_REPLACED);
      })
      .catch((err) => {
        this.emit(EventType.ERROR, err);
      });
  }
}

export default createContext(new PeerConnection());
