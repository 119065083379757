import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';

import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';

dayjs.extend(utc);
dayjs.extend(timezone);

const initialState = {
  timezone: dayjs.tz.guess(),
};

export const user = createSlice({
  name: 'user',
  initialState,
  reducers: {
    changeTimezone: (state, { payload }) => {
      return {
        ...state,
        timezone: payload,
      }
    },
  },
});

export const useUserTimezone = () => {
  const userTimezone = useSelector((state) => state.user.timezone);
  return {
    timezone: userTimezone,
    hourShift: dayjs().tz(userTimezone).format('Z'),
  }
};

export const {
  changeTimezone,
} = user.actions;
