import React from 'react';

import styles from './RoomVideoControls.module.scss';
import AudioButton from './AudioButton';
import VideoButton from './VideoButton';
import StopCallButton from './StopCallButton';
import ScreenShareButton from './ScreenShareButton';
import FullScreenButton from './FullScreenButton';

const RoomVideoControls = () => {
  return (
    <div className={styles.controls}>
      <AudioButton />
      <VideoButton />
      <StopCallButton />
      <ScreenShareButton />
      <FullScreenButton />
    </div>
  );
};

export default RoomVideoControls;
