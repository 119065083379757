import { ConfigProvider } from 'antd';
import { RouterProvider } from 'react-router-dom';
import { theme as antTheme } from 'antd';
import { Provider } from 'react-redux';
import router from './router';
import store from './store';

const theme = {
  primary: '#00008B',
  menuItemSelected: '#314b64',
  menuItemTextSelected: '#001529'
};

function App() {
  return (
    <Provider store={store}>
      <ConfigProvider
        theme={{
          token: {
            colorPrimary: theme.primary,
            // colorBgLayout: 'red', // Фон у Layout (как будто у боди)
          },
          components: {
            Menu: {
              colorItemBgSelected: theme.menuItemSelected,
              // colorItemTextSelected: theme.menuItemTextSelected,
            }
          },
        }}
      >
        <RouterProvider router={router} />
      </ConfigProvider>
    </Provider>
  );
}

export default App;
