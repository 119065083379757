/*
  События:
    'stream' - Функция получит стрим - .on('stream', (stream) => {});
 */
class Emitter {
  constructor() {
    this.events = {};
  }

  // Запускает обработчики событий eventType
  emit(eventType, ...args) {
    if (this.events[eventType]) {
      this.events[eventType].forEach((fn) => fn(...args));
    }
    return this;
  }

  // Подписывает обработчик на событие eventType
  on(eventType, fn) {
    if (this.events[eventType]) {
      this.events[eventType].push(fn);
    } else {
      this.events[eventType] = [fn];
    }
    return this;
  }

  off(eventType, fn) {
    if (eventType && typeof fn === 'function') {
      const listeners = this.events[eventType]
      listeners.splice(
        listeners.findIndex((_fn) => _fn === fn),
        1,
      );
    } else {
      this.events = [];
    }
    return this;
  }
}

export default Emitter
