import React, { useCallback, useMemo, useState } from 'react';
import cn from 'classnames';

import styles from './Withdrawals.module.scss';
import { Col, DatePicker, Result, Row, Skeleton, Space, Table, Tag, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import { useGetPaychecksQuery, useGetWithdrawalsQuery } from '../../../store/apis/billing';
import ContentLoader from '../../../components/ContentLoader';
import { formatDate, formatNumber } from '../../../utils';
import useQueryString from '../../../hooks/useQueryString';
import queryString from 'query-string';
import { useNavigate } from 'react-router';
import ContentError from '../../../components/ContentError';
import routes from '../../../router/routes';

const { Title, Text } = Typography;

const Withdrawals = () => {
  const [t] = useTranslation();
  const navigate = useNavigate();
  const { page = 1, pageSize = 20, ...query } = useQueryString();
  // const [lessonDateDiapason, setLessonDiapason] = useState([dayjs().startOf('date'), dayjs().endOf('date')]);

  // const {
  //   data: withdrawalData,
  //   isLoading,
  //   isFetching,
  //   error,
  // } = useGetWithdrawalsQuery({
  //   fromTime: lessonDateDiapason[0].format(),
  //   toTime: lessonDateDiapason[1].format(),
  //   page,
  //   pageSize,
  //   ...query,
  // });

  const {
    data: paycheks,
    isLoading,
    isFetching,
    error,
  } = useGetPaychecksQuery({
    page, pageSize, ...query,
  });

  // const changeFilterDate = useCallback((dates) => {
  //   const [from, to] = dates;
  //   setLessonDiapason([from.startOf('date'), to.endOf('date')]);
  // }, []);

  const changePagination = useCallback((newPage, newPageSize) => {
    navigate({
      pathname: '',
      search: queryString.stringify({
        page: newPage,
        pageSize: newPageSize,
        ...query,
      }),
    });
  }, [navigate, query]);

  const rangePresets = [
    {
      label: t('За прошлую неделю'),
      value: [dayjs().subtract(1, 'week').startOf('week'), dayjs().subtract(1, 'week').endOf('week')],
    },
    {
      label: t('За прошлый месяц'),
      value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    },
    {
      label: t('За позапрошлый месяц'),
      value: [dayjs().subtract(2, 'month').startOf('month'), dayjs().subtract(2, 'month').endOf('month')],
    },
    {
      label: t('За последние 90 дней'),
      value: [dayjs().subtract(90, 'days'), dayjs()],
    },
  ];

  const columns = useMemo(() => [
    {
      title: t('Дата выплаты'),
      dataIndex: 'createdTime',
      key: 'createdTime',
      render: (isoDate) => formatDate(isoDate, true),
    },
    {
      title: t('За период'),
      dataIndex: 'fromDate',
      key: 'fromDate',
      render: (fromDate, raw) => (
        <Space>
          <Tag color="green">с {formatDate(fromDate)}</Tag>
          <Tag color="blue">по {formatDate(raw.toDate)}</Tag>
        </Space>
      ),
    },
    {
      title: t('Сумма выплаты'),
      dataIndex: 'amount',
      key: 'amount',
      render: (amount) => formatNumber(amount / 100),
    },
    {
      title: t('Сумма выплаты (после уплаты налогов)'),
      dataIndex: 'amountWithoutTaxes',
      key: 'amountWithoutTaxes',
      render: (amount) => formatNumber(amount / 100),
    },
    {
      title: t('Статус'),
      dataIndex: 'isPaid',
      key: 'isPaid',
      render: (isPaid) =>
        isPaid
          ? <Tag color="green">{t('Выплачено')}</Tag>
          : <Tag color="red">{t('Не выплачено')}</Tag>
      ,
    },
  ], [t]);

  if (isLoading) return <Skeleton loading />;
  if (error) return <ContentError error={error} />

  return (
    <div>
      {
        isFetching && (
          <div className={styles.loader}>
            <ContentLoader />
          </div>
        )
      }
      <Row gutter={16} style={{ marginBottom: 42 }}>

        <Col xs={24}>
          <Title level={3}>{t('Выплаты')}</Title>
        </Col>
        <Col xs={24}>
          <Table
            columns={columns}
            dataSource={paycheks?.results}
            onRow={(record) => ({
              onClick: () => {
                navigate(routes.withdrawalsDetails(record.id))
              }
            })}
            pagination={{
              total: paycheks?.count,
              current: page,
              showSizeChanger: true,
              pageSize,
              onChange: changePagination,
            }}
            rowKey="id"
          />
        </Col>
      </Row>
    </div>
  );
};

export default Withdrawals;
