import React, { useCallback, useContext, useEffect, useMemo } from 'react';

import styles from './Application.module.scss';
import { Button, Layout, Menu, Select, Tag, theme } from 'antd';

import { Outlet, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import routes from '../../router/routes';
import localforage from 'localforage';
import useSockets from '../../hooks/useSockets';
import { useDispatch } from 'react-redux';
import { mainApi } from '../../store/apis/mainApi';
import { useGetRescheduledLessonListQuery } from '../../store/apis/schedule';
import { useGetNotificationsQuery } from '../../store/apis/notifications';
import { authApi } from '../../store/apis/auth';
// import { socket } from '../../socket';
import queryString from 'query-string';
import dayjs from 'dayjs';
import { useLazyGetChatDialogsQuery } from '../../store/apis/chats';
import { setInitialDialogs, useChatDialogs } from '../../store/slicers/chat';
import { socket } from '../../socket';

const { Header, Content, Footer, Sider } = Layout;

const Application = () => {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const { data: rescheduledLessons } = useGetRescheduledLessonListQuery();
  const { data: unreadNotification } = useGetNotificationsQuery({ isRead: false, page: -1 });

  const dialogs = useChatDialogs();
  const [getChatDialogs, { isLoading, isFetching }] = useLazyGetChatDialogsQuery({ page: -1 });

  useEffect(() => {
    getChatDialogs().unwrap()
      .then((dialogs) => {
        dispatch(setInitialDialogs(dialogs.results));
      })
      .catch((e) => {
        console.log(JSON.stringify(e.data ?? e, 0, 2));
      });
  }, [dispatch, getChatDialogs]);

  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const newRescheduledLessons = rescheduledLessons && rescheduledLessons.results
    ? rescheduledLessons.results.filter(({ status }) => status.id === 1)
    : [];

  const unreadChatMessages = dialogs.reduce((acc, chat) => {
    return acc + chat.unreadTextMessagesCount + chat.unreadFileMessagesCount;
  }, 0);

  const menuItems = useMemo(() => [
    {
      key: routes.welcome(),
      label: t('Главная'),
      icon: <span>🫡 </span>,
    },
    {
      key: 'personal',
      label: t('Личные данные'),
      type: 'group',
      children: [
        {
          key: routes.profile(),
          label: t('Профиль'),
          icon: <span>🧑‍🏫</span>,
        },
        {
          key: routes.workTime(),
          label: t('Время работы'),
          icon: <span>🕝</span>,
        },
        {
          key: routes.billing(),
          label: t('Статистика'),
          icon: <span>📈</span>,
        },
        {
          key: routes.withdrawals(),
          label: t('Выплаты'),
          icon: <span>💰</span>,
        },
      ],
    },
    {
      key: 'lessons',
      label: t('Уроки'),
      type: 'group',
      children: [
        {
          key: routes.schedule(),
          label: t('Текущее расписание'),
          icon: <span>📅</span>,
        },
        {
          key: routes.rescheduled(),
          label: (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>{t('Ожидающие переноса')}</span>
              {
                (newRescheduledLessons.length > 0) && (
                  <Tag color="#f50">{newRescheduledLessons.length}</Tag>
                )
              }
            </div>
          ),
          icon: <span>↪️</span>,
        },
      ],
    },
    {
      key: 'activity',
      label: t('Активности'),
      type: 'group',
      children: [
        {
          key: routes.students(),
          label: t('Студенты'),
          icon: <span>🧑‍🎓</span>,
        },
        {
          key: routes.chats(),
          label: (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>{t('Чаты')}</span>
              {
                (unreadChatMessages > 0) && (
                  <Tag color="#f50">{unreadChatMessages}</Tag>
                )
              }
            </div>
          ),
          icon: <span>💬</span>,
        },
        {
          key: routes.notifications(),
          label: (
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              <span>{t('Уведомления')}</span>
              {
                (unreadNotification?.results.length > 0) && (
                  <Tag color="#f50">{unreadNotification.results.length}</Tag>
                )
              }
            </div>
          ),
          icon: <span>🔔</span>,
        },
      ],
    },
  ], [
    unreadNotification?.results.length,
    newRescheduledLessons.length,
    t,
    unreadChatMessages,
  ]);

  const handleChangeMenu = useCallback((item) => {
    navigate(item.key);
  }, [navigate]);

  const handleExit = useCallback(async (e) => {
    e.preventDefault();
    await localforage.clear();
    dispatch(authApi.util.resetApiState());
    dispatch(mainApi.util.resetApiState());
    socket.disconnect();
    navigate(routes.root());
  }, [dispatch, navigate])

  useSockets();

  const langs = useMemo(() => [
    {
      label: t('РУ'), value: 'ru',
    },
    {
      label: t('EN'), value: 'en',
    },
    {
      label: t('UZ'), value: 'uz',
    }
  ], [t]);

  const changeLanguage = useCallback((value) => {
    i18n.changeLanguage(value);
    let dayjsKey;
    switch (value) {
      case 'ru': dayjsKey = 'ru'; break;
      case 'uz': dayjsKey = 'uz-latn'; break;
      case 'en': dayjsKey = 'en'; break;
      default: dayjsKey = 'ru';
    }
    dayjs.locale(dayjsKey);
  }, [i18n]);

  return (
    <Layout hasSider>
      <Sider
        className={styles.sider}
        collapsedWidth="0"
        breakpoint="lg"
        width={300}
      >
        <div className={styles.logo}>
          <img src="/images/logo-rus-white.png" alt=""/>
        </div>
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={[location.pathname]}
          items={menuItems}
          onClick={handleChangeMenu}
        />
      </Sider>
      <Layout className={styles.layout}>
        <Header
          className={styles.header}
          style={{ backgroundColor: colorBgContainer }}
        >
          <div className={styles.headerWrapper}>
            {process.env.REACT_APP_ENV === 'development' && (
              <button
                type="button"
                onClick={() => {
                  const roomId = prompt('room id');
                  navigate({
                    pathname: routes.room(),
                    search: queryString.stringify({
                      roomId,
                      studentId: '50d13650-171e-11ee-b4f6-83a33ee19595',
                    }),
                  });
                }}
              >cc</button>
            )}
            {process.env.REACT_APP_ENV === 'development' && (
              <button
                type="button"
                onClick={() => {
                  localforage.getItem('credentials')
                    .then((data) => {
                      localforage.setItem('credentials', { ...data, accessToken: '123' })
                    });
                }}
              >
                kill access token
              </button>
            )}
            <Select
              defaultValue="ru"
              onChange={changeLanguage}
              options={langs}
            />
            <Button type="text" onClick={handleExit}>
              🚪 {t('Выход')}
            </Button>
          </div>
        </Header>
        <Content className={styles.content} style={{ backgroundColor: colorBgContainer }}>
          <Outlet />
        </Content>
        <Footer className={styles.footer}>
          Bilimdosh ©2023 Created by Endpoint with ❤️️
        </Footer>
      </Layout>
    </Layout>
  );
};

export default Application;
