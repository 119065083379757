export default class EventType {
  static STREAM = 'stream';
  static SCREEN_STREAM = 'screenStream';
  static STOPPED_SCREEN_STREAM = 'stoppedSCreenStream';
  static ERROR = 'error';
  static TRACK_REPLACED = 'trackReplaced';

  // RTC STATE
  static CREATED_PEER_CONNECTION = 'CREATED_PEER_CONNECTION';
  static LOCAL_DESCRIPTION_SETTED = 'LOCAL_DESCRIPTION_SETTED';
  static ICE_CANDIDATES_SETTED = 'ICE_CANDIDATES_SETTED';

  // RTC TYPES
  static ICE_CANDIDATE = 'iceCandidate';
  static REMOTE_STREAM = 'remoteStream';
  static REMOTE_DESCRIPTION = 'remoteDescription';
  static REMOTE_ICE_CANDIDATE = 'remoteIceCandidate';
  static LOCAL_DESCRIPTION = 'localDescription';
  static OFFER = 'offer';


  // STATES
  static CONNECTION_STATE_CHANGE = 'connectionStateChange';
  static ICE_STATE_CHANGED = 'iceStateChanged';
}
