import Emitter from './Emitter'
import { createContext } from 'react';
import EventType from './EventType';
import { setAudioEnabled, setMediaLoading, setScreenShareEnabled, setVideoEnabled } from '../store/slicers/room';
import NoMediaDeviceError from './errors/NoMediaDevice';
import NoAudioError from './errors/NoAudioError';
import CameraAndMicPermissionDeniedError from './errors/CameraAndMicPermissionDeniedError';
import store from '../store';

class MediaDevice extends Emitter {

  start() {
    store.dispatch(setMediaLoading(true));

    if (this.stream) {
      this.emit(EventType.STREAM, this.stream, 'existed');
      store.dispatch(setMediaLoading(false));
      return this;
    }

    if (!navigator.mediaDevices) {
      this.emit(EventType.ERROR, new NoMediaDeviceError());
      store.dispatch(setMediaLoading(false));
      return this;
    }

    navigator.mediaDevices.enumerateDevices()
      .then((devices) => {
        let constraints = {
          video: false,
          audio: false,
        };

        devices.forEach((device) => {
          if (device.kind === 'videoinput') {
            constraints.video = {
              width: { ideal: 640 },
              height: { ideal: 480 },
              frameRate: { ideal: 30 },
              codec: 'H264',
            }
            store.dispatch(setVideoEnabled(true));
          }
          if (device.kind === 'audioinput') {
            constraints.audio = true;
            store.dispatch(setAudioEnabled(true));
          }
        });

        if (!constraints.audio) {
          store.dispatch(setMediaLoading(false));
          throw new NoAudioError();
        }

        return constraints;
      })
      .then((constraints) => {
        return navigator.mediaDevices.getUserMedia(constraints)
      })
      .then((stream) => {
        this.stream = stream;
        this.emit(EventType.STREAM, stream, 'new');
        store.dispatch(setMediaLoading(false));
      })
      .catch((err) => {
        if (err.message === 'Permission denied') {
          this.emit(EventType.ERROR, new CameraAndMicPermissionDeniedError());
        } else {
          this.emit(EventType.ERROR, err);
        }
        store.dispatch(setMediaLoading(false));
      })
    return this;
  };

  startDisplayMedia() {
    store.dispatch(setMediaLoading(true));
    navigator.mediaDevices.getDisplayMedia({ video: true, audio: false })
      .then((stream) => {
        this.screenStream = stream;
        this.screenStream.addTrack(this.stream.getAudioTracks()[0]);
        this.emit(EventType.SCREEN_STREAM, this.screenStream);
        this.screenStream.getVideoTracks()[0].onended = () => {
          this.stopDisplayMedia();
          this.emit(EventType.STOPPED_SCREEN_STREAM);
        };
        store.dispatch(setMediaLoading(false));
        store.dispatch(setScreenShareEnabled(true));
      })
      .catch((err) => {
        if (err.message !== 'Permission denied') {
          this.emit(EventType.ERROR, err);
        }
        store.dispatch(setMediaLoading(false));
      })
    return this;
  }

  stopDisplayMedia() {
    if (this.screenStream) {
      this.screenStream.getVideoTracks().forEach((t) => {
        t.stop();
      });
      this.screenStream = null;
    }
    if (this.stream) {
      this.emit(EventType.STREAM, this.stream);
    }
    store.dispatch(setScreenShareEnabled(false));
  }

  toggle(type) {
    if (this.stream) {
      this.stream[`get${type}Tracks`]().forEach((t) => {
        t.enabled = !t.enabled;
        if (type === 'Video') {
          store.dispatch(setVideoEnabled(t.enabled));
        } else if (type === 'Audio') {
          store.dispatch(setAudioEnabled(t.enabled));
        }
      })
    }

    return this
  }

  stop() {
    if (this.stream) {
      this.stream.getTracks().forEach((t) => { t.stop() });
    }
    // удаляем все обработчики всех событий
    this.off();
    this.stream = null;
    store.dispatch(setVideoEnabled(false));

    return this;
  }
}

export default createContext(new MediaDevice());
