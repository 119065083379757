import { mainApi } from './mainApi';
import apiRoutes from '../../apiRoutes';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getChatDialogs: build.query({
      query: (params) => ({
        url: apiRoutes.dialogs(),
        params,
      }),
    }),
    getChatTextMessages: build.query({
      query: (params) => ({
        url: apiRoutes.chatMessages(),
        params,
      }),
    }),
    getChatFileMessages: build.query({
      query: (params) => ({
        url: apiRoutes.chatFiles(),
        params,
      }),
    }),
    startDialog: build.mutation({
      query: (body) => ({
        url: apiRoutes.dialogs(),
        body,
        method: 'POST',
      }),
    }),

    sendMessage: build.mutation({
      query: (body) => ({
        url: apiRoutes.chatMessages(),
        body,
        method: 'POST',
      }),
    }),
    sendFile: build.mutation({
      query: (body) => ({
        url: apiRoutes.chatFiles(),
        body,
        method: 'POST',
      }),
    }),

    readAllTextMessages: build.mutation({
      query: (chatId) => ({
        url: apiRoutes.readAllMessages(chatId),
        method: 'PUT',
      }),
    }),
    readAllFileMessages: build.mutation({
      query: (chatId) => ({
        url: apiRoutes.readAllFiles(chatId),
        method: 'PUT',
      }),
    }),
  }),
});

export const {
  useLazyGetChatDialogsQuery,
  useLazyGetChatTextMessagesQuery,
  useLazyGetChatFileMessagesQuery,
  useStartDialogMutation,
  useSendMessageMutation,
  useSendFileMutation,
  useReadAllTextMessagesMutation,
  useReadAllFileMessagesMutation,
} = mainApi;
