import { createApi } from '@reduxjs/toolkit/query/react';
import apiRoutes from '../../apiRoutes';
import { baseQuery } from '../utils';


export const authApi = createApi({
  reducerPath: 'auth',
  baseQuery: baseQuery,
  tagTypes: [],
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (body) => ({
        url: apiRoutes.login(),
        method: 'POST',
        body,
      }),
    }),

    getMe: builder.query({
      query: (params) => ({
        url: apiRoutes.me(),
        params,
      }),
    })
  })
});

export const {
  useLoginMutation,
  useGetMeQuery,
  useLazyGetMeQuery,
} = authApi;
