import React, { useCallback, useEffect, useState } from 'react';

import styles from './Schedule.module.scss';
import { useTranslation } from 'react-i18next';
import { useLazyGetScheduleQuery } from '../../../store/apis/schedule';
import { Avatar, Button, Card, Col, Row, Space, Tooltip, Typography } from 'antd';
import ContentLoader from '../../../components/ContentLoader';
import dayjs from 'dayjs';
import { useUserTimezone } from '../../../store/slicers/user';
import { formatDate, formatTime } from '../../../utils';
import { append, identity, isEmpty, isNil, keys } from 'ramda';
import DateRangePicker from '../../../components/DateRangePicker';
import CancelLesson from '../../../containers/CancelLesson';
import RescheduleLesson from '../../../containers/RescheduleLesson';
import ContentEmpty from '../../../components/ContentEmpty';
import StartLesson from '../../../containers/StartLesson';
import ReconnectToLesson from '../../../containers/ReconnectToLesson';
import StartChat from '../../../containers/StartChat';
import ContentError from '../../../components/ContentError';

const { Title, Text } = Typography;
const { Meta } = Card;

const Schedule = () => {
  const [t, i18n] = useTranslation();
  const { timezone, hourShift } = useUserTimezone();
  const [lessonDateDiapason, setLessonDiapason] = useState([dayjs().startOf('date'), dayjs().endOf('date')]);

  const [getSchedule, {
    data: schedule,
    isLoading: isScheduleLoading,
    isFetching: isScheduleFetching,
    error,
  }] = useLazyGetScheduleQuery();

  useEffect(() => {
    const [from, to] = lessonDateDiapason;
    const params = {
      fromTime: from.format(),
      toTime: to.format(),
    };
    getSchedule(params);
  }, [getSchedule, lessonDateDiapason]);

  const changeFilterDate = useCallback((dates) => {
    const [from, to] = dates;
    setLessonDiapason([from.startOf('date'), to.endOf('date')]);
  }, []);

  if (isScheduleLoading || isScheduleFetching) return <ContentLoader />
  if (error) return <ContentError error={error} />
  if (!schedule) return <div>Ничего не найдено</div>

  const scheduleGroupedByDate = schedule.reduce((acc, scheduleItem) => {
    const date = dayjs(scheduleItem.lessonTime);
    const dateKey = date.format('YYYY-MM-DD');

    if (acc[dateKey]) {
      return { ...acc, [dateKey]: append(scheduleItem, acc[dateKey]) };
    }

    return { ...acc, [dateKey]: [scheduleItem] };
  }, {});

  return (
    <Row>
      <Col xs={24} style={{ marginBottom: '.6em' }}>
        <Space >
          <Title level={3}>{t('Расписание')} - </Title>
          <Title level={5} italic>
            {t('Время показывается в часовом поясе - ')}
            <Text strong>{timezone} {hourShift}</Text>
          </Title>
        </Space>
      </Col>
      <Col xs={24} style={{ marginBottom: '2em' }}>
        <Space>
          <Text>{t('Показаны уроки за период')}</Text>
          <DateRangePicker
            onChange={changeFilterDate}
            value={lessonDateDiapason}
          />
        </Space>
      </Col>
      {
        (isEmpty(scheduleGroupedByDate) || isNil(scheduleGroupedByDate))
        ? <ContentEmpty label={t('За выбранный период уроков нет')} />
        : keys(scheduleGroupedByDate).map((dateKey) => (
          <Col xs={24} key={dateKey} className={styles.item}>
            <Title level={4} style={{ textTransform: 'capitalize' }}>
              {
                dayjs(dateKey).calendar(null, {
                  sameDay: `[${t('Сегодня')}]`, // The same day ( Today at 2:30 AM )
                  nextDay: `[${t('Завтра')}]`, // The next day ( Tomorrow at 2:30 AM )
                  nextWeek: 'dddd - DD.MM', // The next week ( Sunday at 2:30 AM )
                  lastDay: `[${t('Вчера')}]`, // The day before ( Yesterday at 2:30 AM )
                  lastWeek: '[Last] dddd', // Last week ( Last Monday at 2:30 AM )
                  sameElse: 'DD.MM.YYYY' // Everything else ( 7/10/2011 )
                })
              }
            </Title>
            <Row gutter={[24, 24]}>
              {
                scheduleGroupedByDate[dateKey].map(({
                  id,
                  lessonTime,
                  lesson,
                  schedule,
                  cancelAvailable,
                  transferAvailable,
                  startAvailable,
                }) => (
                  <Col md={24} lg={12} key={id}>
                    <Card
                      title={formatDate(dayjs(lessonTime).tz(timezone))}
                      extra={<Text strong>{formatTime(dayjs(lessonTime).tz(timezone))}</Text>}
                      actions={[
                        <StartChat
                          trigger={
                            <Tooltip title={t('Написать ученику в Чате')}>
                              <Button type="button">
                                💬
                              </Button>
                            </Tooltip>
                          }
                          userId={schedule.student.id}
                        />,
                        cancelAvailable && <CancelLesson key="close" lessonTime={lessonTime} scheduleId={schedule.id} />,
                        transferAvailable && <RescheduleLesson key="transfer" scheduleId={schedule.id} lessonTime={lessonTime} />,
                        startAvailable && <StartLesson
                          key="start"
                          roomId={id}
                          scheduleId={schedule.id}
                          studentId={schedule.student.id}
                          lessonTime={lessonTime}
                        />,
                        (lesson && lesson.state?.id === 1) && <ReconnectToLesson
                          key="reconnect"
                          roomId={id}
                          studentId={schedule.student.id}
                          show={lesson && lesson.state?.id === 1}
                        />,
                      ].filter(identity)}
                      size="small"
                    >
                      <Meta
                        avatar={<Avatar src={schedule.student?.photoUrl} />}
                        title={schedule.student.fullName?.[i18n.language]}
                        description={schedule.type?.name?.[i18n.language]}
                      />
                    </Card>
                  </Col>
                ))
              }
            </Row>
          </Col>
        ))
      }
    </Row>
  );
};

export default Schedule;
