import { invalidateOn } from '../utils';
import apiRoutes from '../../apiRoutes';
import { mainApi } from './mainApi';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getServiceConfig: build.query({
      query: (params) => ({
        url: apiRoutes.serviceConfig(),
        params,
      }),
      providesTags: ['SERVICE_CONFIG'],
    }),
  }),
})

export const {
  useGetServiceConfigQuery,
} = mainApi;
