import React, { useCallback, useEffect, useMemo } from 'react';
import cn from 'classnames';

import styles from './SchedulesOutsideWorkTimeModal.module.scss';
import { useTranslation } from 'react-i18next';
import { useCheckNewWorkTimeSchedulesMutation, useDeleteSchedulesMutation } from '../../store/apis/workTime';
import { Button, Col, message, Modal, Result, Row, Space, Table, Typography } from 'antd';
import ContentLoader from '../../components/ContentLoader';
import { formatDate, getTimeStringByHourId } from '../../utils';
import RescheduleLesson from '../RescheduleLesson';
import CancelLesson from '../CancelLesson';
import { pluck } from 'ramda';
import { weekDayNames } from '../../constants';

const { Title, Text } = Typography;

const SchedulesOutsideWorkTimeModal = ({
  workSchedule,
  open,
  onClose,
  onConfirm,
  confirmLoading,
}) => {
  const [t, i18n] = useTranslation();

  const [
    checkNewWorkTimes,
    { data: schedules, isLoading, isFetching }
  ] = useCheckNewWorkTimeSchedulesMutation();

  const [
    deleteSchedules,
    { isLoading: isDeleteSchedulesLoading, isFetching: isDeleteSchedulesFetching },
  ] = useDeleteSchedulesMutation();

  const closeModal = useCallback(() => {
    onClose();
  }, [onClose]);

  const confirm = useCallback(() => {
    onConfirm();
  }, [onConfirm]);

  const deleteAllSchedules = useCallback(async () => {
    const idList = pluck('id', schedules);
    try {
      await deleteSchedules({
        idList,
      }).unwrap();
      await checkNewWorkTimes(workSchedule).unwrap();
      message.success(t('Бронь отменена, ученики будут уведомлены'));
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [checkNewWorkTimes, deleteSchedules, schedules, t, workSchedule]);


  useEffect(() => {
    if (open && workSchedule) {
      checkNewWorkTimes(workSchedule).unwrap()
        .then(() => {})
        .catch((e) => {
          console.error(JSON.stringify(e.data ?? e, 0, 2));
        });
    }
  }, [checkNewWorkTimes, workSchedule, open]);

  const columns = useMemo(() => [
    {
      title: t('Занятие'),
      dataIndex: 'subject',
      key: 'subject',
    },
    {
      title: t('Студент'),
      dataIndex: 'student',
      key: 'student',
    },
    {
      title: t('Тип брони'),
      dataIndex: 'scheduleType',
      key: 'scheduleType',
    },
    {
      title: t('Расписание (день или периодичность)'),
      dataIndex: 'timeSlot',
      key: 'timeSlot',
    },
  ], [t]);

  const tableData = schedules
    ? schedules.map((schedule) => ({
      key: schedule.id,
      subject: schedule.subject.name[i18n.language],
      student: schedule.student.fullName?.[i18n.language],
      scheduleType: schedule.type.name[i18n.language],
      timeSlot: schedule.type.id === 1
        ? `${weekDayNames[schedule.timeSpan.weekDayId]} ${getTimeStringByHourId(schedule.timeSpan.hourId)}`
        : `${formatDate(schedule.startAtDate)} ${getTimeStringByHourId(schedule.timeSpan.hourId)}`,
    }))
    : []

  return (
    <Modal
      title={t('Проверка нового расписания')}
      open={open}
      onOk={confirm}
      onCancel={closeModal}
      closable={false}
      width={1000}
      okText={t('Изменить свое рабочее время')}
      cancelText={t('Отменить')}
      confirmLoading={confirmLoading}
    >
      {
        (isLoading || isFetching)
          ? <ContentLoader label={t('Подождите, идет проверка')} />
          : (schedules && schedules.length > 0)
            ? (
              <div>
                <Title level={4}>{t('У Вас есть бронь, которая выходит за рамки выбранного Вами нового расписания')}</Title>
                <Text italic>
                  {t('Вам нужно решить, отменить все брони или оставить и их')}
                </Text>
                {'. '}
                <Text italic>
                  {t('Если Вы не отмените брони то Вам обязательно нужно будет проводить их согласно старому расписанию, однако новые студенты будут бронировать Ваше время согласно новому времени')}
                </Text>
                <Table
                  columns={columns}
                  dataSource={tableData}
                  pagination={false}
                  loading={isDeleteSchedulesLoading || isDeleteSchedulesFetching}
                />
                <Row justify="center" style={{ marginTop: 20, marginBottom: 60 }}>
                  <Col>
                    <Button
                      type="primary"
                      danger
                      onClick={deleteAllSchedules}
                      loading={isDeleteSchedulesLoading || isDeleteSchedulesFetching}
                    >
                      {t('Удалить все брони, которые не входят в новое расписание')}
                    </Button>
                  </Col>
                </Row>
              </div>
            ) : (
              <Result
                status="success"
                title={t('У Вас нет броней от студентов вне установленного времени')}
                subTitle={t('Вы можете установить новое, выбранное Вами время работы')}
              />
            )
      }
    </Modal>
  );
};

export default SchedulesOutsideWorkTimeModal;
