import { createBrowserRouter } from 'react-router-dom';
import Entry from '../views/Entry';
import routes from './routes';
import Application from '../views/Application';
import Welcome from '../contents/Welcome';
import ErrorPage from '../views/ErrorPage';

import ProtectRoute from './ProtectRoute';
import Profile from '../contents/personal/Profile';
import WorkTime from '../contents/personal/WorkTime';
import WorkTimeAddForm from '../contents/personal/WorkTimeAddForm';
import Billing from '../contents/personal/Billing';
import Schedule from '../contents/lessons/Schedule';
import RescheduledLessons from '../contents/lessons/RescheduledLessons';
import MainContainer from '../contents/activities/Chats';
import Room from '../views/Room';
import Students from '../contents/activities/Students';
import Withdrawals from '../contents/personal/Withdrawals';
import Notifications from '../contents/activities/Notifications';
import WithdrawalDetails from '../contents/personal/WithdrawalDetails';


export default createBrowserRouter([
  {
    path: routes.root(),
    element: <Entry />,
    errorElement: <ErrorPage />
  },
  {
    path: routes.app(),
    element: <ProtectRoute><Application /></ProtectRoute>,
    children: [
      {
        path: routes.welcome(),
        element: <Welcome />,
      },
      {
        path: routes.profile(),
        element: <Profile />,
      },
      {
        path: routes.workTime(),
        element: <WorkTime />,
      },
      {
        path: routes.workTimeCreate(),
        element: <WorkTimeAddForm />
      },
      {
        path: routes.schedule(),
        element: <Schedule />
      },
      {
        path: routes.rescheduled(),
        element: <RescheduledLessons />
      },
      {
        path: routes.chats(),
        element: <MainContainer />
      },
      {
        path: routes.billing(),
        element: <Billing />,
      },
      {
        path: routes.students(),
        element: <Students />,
      },
      {
        path: routes.withdrawals(),
        element: <Withdrawals />,
      },
      {
        path: routes.withdrawalsDetails(':id'),
        element: <WithdrawalDetails />,
      },
      {
        path: routes.notifications(),
        element: <Notifications />,
      }
    ],
  },
  {
    path: routes.room(),
    element: <ProtectRoute><Room /></ProtectRoute>
  }
]);
