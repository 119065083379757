// import cn from 'classnames';

import styles from './WithdrawalDetails.module.scss';
import { useParams } from 'react-router';
import { useGetPaycheckDetailsQuery } from '../../../store/apis/billing';
import { Skeleton, Space, Table, Tabs, Tag, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { formatDate, formatNumber, toSom } from '../../../utils';

const WithdrawalDetails = () => {
  const [t, i18n] = useTranslation();
  const { id } = useParams();

  const {
    data: paycheck,
    isLoading,
  } = useGetPaycheckDetailsQuery(id, { skip: !id });

  const incomeCols = [
    {
      key: 'lessonTime',
      dataIndex: 'lessonTime',
      title: t('Время урока'),
      render: (date) => formatDate(date, true),
    },
    {
      key: 'incomeAmount',
      dataIndex: 'incomeAmount',
      title: t('Начисления'),
      render: (amount) => formatNumber(toSom(amount)),
    },
    {
      key: 'student',
      dataIndex: 'student',
      title: t('Студент'),
      render: (student) => student.fullName?.[i18n.language],
    },
  ];

  const penaltyCols = [
    {
      key: 'lessonTime',
      dataIndex: 'lessonTime',
      title: t('Время урока'),
      render: (date) => formatDate(date, true),
    },
    {
      key: 'penaltyAmount',
      dataIndex: 'penaltyAmount',
      title: t('Штраф'),
      render: (amount) => formatNumber(toSom(amount)),
    },
    {
      key: 'student',
      dataIndex: 'student',
      title: t('Студент'),
      render: (student) => student.fullName?.[i18n.language],
    },
  ];

  const tabsItems = [
    {
      key: 1,
      label: t('Начисления'),
      children: (
        <div >
          <Typography.Title level={5}>{t('Начисления')}</Typography.Title>
          <Table
            columns={incomeCols}
            dataSource={paycheck?.incomes || []}
            pagination={{
              pageSize: 50,
            }}
          />
        </div>
      )
    },
    {
      key: 2,
      label: t('Штрафы'),
      children: (
        <div>
          <Typography.Title level={5}>{t('Штрафы')}</Typography.Title>
          <Table
            columns={penaltyCols}
            dataSource={paycheck?.penalties || []}
            pagination={{
              pageSize: 50,
            }}
          />
        </div>
      )
    }
  ]

  if (isLoading) return <Skeleton />;

  return (
    <div>
      <Typography.Title level={4}>
        {t('Детали ведомости')} ({formatDate(paycheck.fromDate)} - {formatDate(paycheck.toDate)})
      </Typography.Title>
      <Space size="large" align="start">
        <div>
          <Typography.Text type="secondary">{t('Начисленная сумма')}</Typography.Text>
          <br />
          <Typography.Title level={4}>{formatNumber(toSom(paycheck.amount))}</Typography.Title>
        </div>
        <div>
          <Typography.Text type="secondary">{t('Сумма после уплаты налогов')}</Typography.Text>
          <br />
          <Typography.Title level={4}>{formatNumber(toSom(paycheck.amountWithoutTaxes))}</Typography.Title>
        </div>
        <div>
          <Typography.Text type="secondary">{t('Статус')}</Typography.Text>
          <br />
          <Tag color={paycheck.isPaid ? 'green' : 'red'}>{paycheck.isPaid ? t('Выплачено') : t('Невыплачено')}</Tag>
        </div>
      </Space>

      <div style={{ marginTop: 20 }}>
        <Typography.Title level={4}>
          {t('Детально')}
        </Typography.Title>
        <Tabs type="card" defaultActiveKey={1} items={tabsItems} />
      </div>
    </div>
  );
};

export default WithdrawalDetails;
