import React from 'react';
import cn from 'classnames';
import { Typography } from 'antd';
import styles from './ChatMessage.module.scss';
import { split } from 'ramda';
import { formatDate } from '../../utils';

const { Text } = Typography;

const ChatMessage = ({ children }) => {

  return (
    <div className={styles.message}>
      {children}
    </div>
  );
};

const MessageItem = ({
  text,
  fullName,
  isMyMessage,
  sendTime,
}) => {
  const messageClasses = cn({
    [styles.messageItem]: true,
    [styles.messageItemLeft]: !isMyMessage,
    [styles.messageItemRight]: isMyMessage,
  });

  const parsedMessage = typeof text === 'string'
    ? split('\n', text)
      .map((text, i) => (
        (i === 0)
          ? text
          : <React.Fragment key={i}><br />{text}</React.Fragment>
      ))
    : text;

  return (
    <div className={messageClasses}>
      {!isMyMessage && <div className={styles.messageItemName}>{fullName}</div>}
      <div className={styles.messageItemText}>
        {parsedMessage}
      </div>
      <div className={styles.messageItemTime}>
        {formatDate(sendTime, true)}
      </div>
    </div>
  );
};

ChatMessage.MessageItem = MessageItem;

export default ChatMessage;
