import React from 'react';
import cn from 'classnames';

import styles from './DateRangePicker.module.scss';
import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const DateRangePicker = ({
  onChange,
  value,
}) => {
  const [t] = useTranslation();
  const rangePresets = [
    {
      label: t('Неделя вперед'),
      value: [dayjs(), dayjs().add(7, 'd')],
    },
    {
      label: t('2 недели вперед'),
      value: [dayjs(), dayjs().add(14, 'd')],
    },
    {
      label: t('30 дней вперед'),
      value: [dayjs(), dayjs().add(30, 'd')],
    },
    {
      label: t('90 дней вперед'),
      value: [dayjs(), dayjs().add(90, 'd')],
    },
  ];

  return (
    <DatePicker.RangePicker
      presets={rangePresets}
      format="DD.MM.YYYY"
      onChange={onChange}
      value={value}
      allowClear={false}
    />
  );
};

export default DateRangePicker;
