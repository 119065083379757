import { createSlice } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { values } from 'ramda';
import { reverseIfExists } from '../../utils';

const initialState = {
  chats: {},
  activeChatId: null,
  activeTabId: 'textMessages',
};

export const chat = createSlice({
  name: 'chat',
  initialState,
  reducers: {
    setInitialDialogs: (state, { payload: dialogs }) => {
      dialogs.forEach((chat) => {
        state.chats[chat.id] = chat;
      });
    },

    changeTab: (state, { payload: tabId }) => {
      state.activeTabId = tabId;
    },

    setActiveChat: (state, { payload: chatId }) => {
      state.activeChatId = chatId;
    },

    setBunchTextMessages: (state, { payload: { chatId, messages } }) => {
      if (state.chats[chatId]) {
        state.chats[chatId].textMessages = reverseIfExists(messages);
        return;
      }
      state.chats[chatId] = { textMessages: messages };
    },
    setTextMessage: (state, { payload: { chatId, message } }) => {
      if (!state.chats[chatId]) return;

      if (state.chats[chatId].textMessages) {
        state.chats[chatId].textMessages.push(message);
      } else {
        state.chats[chatId].textMessages = [message];
      }
      state.chats[chatId].lastMessage = message;
    },

    setBunchFileMessages: (state, { payload: { chatId, messages } }) => {
      if (state.chats[chatId]) {
        state.chats[chatId].fileMessages = reverseIfExists(messages);
        return;
      }
      state.chats[chatId] = { fileMessages: messages };
    },
    setFileMessage: (state, { payload: { chatId, message } }) => {
      if (!state.chats[chatId]) return;


      if (state.chats[chatId].fileMessages) {
        state.chats[chatId].fileMessages.push(message);
        return;
      }
      state.chats[chatId].fileMessages = [message];
    },

    incrementUnreadTextMessages: (state, { payload: chatId }) => {
      if (state.chats[chatId]) state.chats[chatId].unreadTextMessagesCount += 1;
    },
    incrementUnreadFileMessages: (state, { payload: chatId }) => {
      if (state.chats[chatId]) state.chats[chatId].unreadFileMessagesCount += 1;
    },

    resetUnreadTextMessages: (state, { payload: chatId }) => {
      state.chats[chatId].unreadTextMessagesCount = 0;
    },
    resetUnreadFileMessages: (state, { payload: chatId }) => {
      state.chats[chatId].unreadFileMessagesCount = 0;
    },
  },
});

export const useChatDialogs = () => {
  const dialogs = useSelector((state) => state.chat.chats);
  return values(dialogs);
};

export const useActiveChat = () => {
  const activeChatId = useSelector((state) => state.chat.activeChatId);
  const chat = useSelector((state) => state.chat.chats[activeChatId]);

  return [activeChatId, chat];
};

export const useActiveTab = () => {
  return useSelector((state) => state.chat.activeTabId);
};

export const useChatTextMessages = (chatId) => {
  const chatData = useSelector((state) => state.chat.chats[chatId]);
  if (chatData) return chatData.textMessages;
  return [];
};

export const useChatFileMessages = (chatId) => {
  const chatData = useSelector((state) => state.chat.chats[chatId]);
  if (chatData) return chatData.fileMessages;
  return [];
};

export const {
  setInitialDialogs,
  setActiveChat,
  setBunchTextMessages,
  setTextMessage,
  setBunchFileMessages,
  setFileMessage,
  incrementUnreadTextMessages,
  incrementUnreadFileMessages,
  resetUnreadTextMessages,
  resetUnreadFileMessages,
  changeTab,
} = chat.actions;
