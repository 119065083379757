import { invalidateOn } from '../utils';
import apiRoutes from '../../apiRoutes';
import { mainApi } from './mainApi';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getMyWorkTime: build.query({
      query: (params) => ({
        url: apiRoutes.workTime(),
        params,
      }),
      providesTags: ['WORK_TIMES'],
    }),

    createNewWorkTime: build.mutation({
      query: (body) => ({
        url: apiRoutes.workTime(),
        method: 'POST',
        body,
      }),
      invalidatesTags: invalidateOn({ success: ['WORK_TIMES'] }),
    }),

    deleteWorkTime: build.mutation({
      query: (id) => ({
        url: apiRoutes.workTime(id),
        method: 'DELETE',
      }),
      invalidatesTags: invalidateOn({ success: ['WORK_TIMES'] }),
    }),

    checkNewWorkTimeSchedules: build.mutation({
      query: (body) => ({
        url: apiRoutes.checkNewWorkTimeSchedules(),
        method: 'POST',
        body,
      }),
    }),

    deleteSchedules: build.mutation({
      query: (body) => ({
        url: apiRoutes.deleteSchedules(),
        method: 'DELETE',
        body,

      }),
    }),

  }),
})

export const {
  useGetMyWorkTimeQuery,
  useCreateNewWorkTimeMutation,
  useDeleteWorkTimeMutation,
  useCheckNewWorkTimeSchedulesMutation,
  useDeleteSchedulesMutation,
} = mainApi;
