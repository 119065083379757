import styles from './RoomWarning.module.scss';
import { FiAlertTriangle } from 'react-icons/fi';
import { useUnexpectedBehaviourState } from '../../../store/slicers/room';
import { useTranslation } from 'react-i18next';
import { Button } from 'antd';

const UnexpectedBehaviourModal = () => {
  const [t] = useTranslation();
  const unexpectedBehaviour = useUnexpectedBehaviourState();

  const causes = {
    NO_MEDIA_DEVICE_STREAM: t('Мы не смогли получить видео или аудио с Вашего устройства'),
    CANNOT_CREATE_ROOM: t('Не могу создать или получить данные комнаты'),
    REMOTE_DESCRIPTION_FAILED: t('К сожалению произошла ошибка при подключении'),
  }

  if (!unexpectedBehaviour.show) return null;

  return (
    <div className={styles.warning}>
      <div className={styles.warningWrapper}>
        <div className={styles.warningIcon}>
          <FiAlertTriangle/>
        </div>
        <h4 className={styles.warningTitle}>
          {causes[unexpectedBehaviour.cause]}
        </h4>
        <p
          className={styles.warningText}
        >
          {t('Попробуйте обновить страницу')}
        </p>
        <div className={styles.warningActions}>
          <Button
            type="primary"
            onClick={() => {
              window.location.reload();
            }}
          >
            {t('Обновить страницу')}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default UnexpectedBehaviourModal;
