import styles from './RoomWarning.module.scss';
import { FiAlertTriangle } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import useMedia from '../../../rtc/hooks/useMedia';
import { useEffect, useState } from 'react';
import EventType from '../../../rtc/EventType';
import ErrorMessage from '../../../rtc/ErrorMessage';

const NoMicrophoneWarning = () => {
  const [open, setOpen] = useState(false);
  const [t] = useTranslation();
  const MediaDevice = useMedia();

  useEffect(() => {
    MediaDevice.on(EventType.ERROR, (error) => {
      if (error.name === ErrorMessage.NO_AUDIO) {
        setOpen(true);
      }
    })
  }, [MediaDevice]);

  if (!open) return null;

  return (
    <div className={styles.warning}>
      <div className={styles.warningWrapper}>
        <div className={styles.warningIcon}>
          <FiAlertTriangle/>
        </div>
        <h4 className={styles.warningTitle}>
          {t('Вам нужен хотя бы микрофон для того, чтобы подключиться к уроку')}
        </h4>
      </div>
    </div>
  );
};

export default NoMicrophoneWarning;
