import React, { useEffect, useRef, useState } from 'react';

import styles from './ChatBoxTextMessages.module.scss';
import { useTranslation } from 'react-i18next';
import ChatMessage from '../../../../components/ChatMessage';
import { formatTime } from '../../../../utils';
import {
  useLazyGetChatTextMessagesQuery,
  useReadAllTextMessagesMutation,
  useSendMessageMutation
} from '../../../../store/apis/chats';
import {
  resetUnreadTextMessages,
  setBunchTextMessages,
  setTextMessage,
  useActiveChat,
  useChatTextMessages
} from '../../../../store/slicers/chat';
import { useDispatch } from 'react-redux';
import { Button, Col, Input, Row } from 'antd';
import ContentLoader from '../../../../components/ContentLoader';
import { useHotkeys } from 'react-hotkeys-hook';


const ChatBoxTextMessages = () => {
  const [t, i18n] = useTranslation();
  const [activeChatId, activeChat] = useActiveChat();
  const boxRef = useRef();
  const textAreaRef = useRef();
  const [getChatMessage, { isLoading, isFetching }] = useLazyGetChatTextMessagesQuery();
  const [sendChatMessage, { isLoading: isSendLoading, isSendFetching }] = useSendMessageMutation();
  const [readAllTextMessages] = useReadAllTextMessagesMutation();
  const [messageValue, setMessageValue] = useState('');

  const dispatch = useDispatch();
  const messages = useChatTextMessages(activeChatId);

  const sendMessage = async () => {
    try {
      const message = await sendChatMessage({ chatId: activeChatId, text: messageValue }).unwrap();
      setMessageValue('');
      dispatch(setTextMessage({ chatId: message.chatId, message }));
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  };

  const fieldRef = useHotkeys(['enter', 'ctrl+enter', 'meta+enter'], async (_, hotkey) => {
    if (!hotkey.ctrl && !hotkey.meta) {
      await sendMessage();
      textAreaRef.current.focus()
      return;
    }
    setMessageValue(`${messageValue}\n`);
  }, {
    enableOnFormTags: true,
    preventDefault: true,
  });

  useEffect(() => {
    if (boxRef.current) boxRef.current.scrollIntoView();
  }, [messages]);

  useEffect(() => {
    if (!activeChatId) return;
    const chatMessageQuery = {
      chatId: activeChatId,
      page: -1,
    }
    getChatMessage(chatMessageQuery).unwrap()
      .then((messages) => {
        dispatch(setBunchTextMessages({
          chatId: activeChatId,
          messages: messages.results,
        }));
      })
      .catch((e) => {
        console.log(JSON.stringify(e.data ?? e, 0, 2));
      })
  }, [activeChatId, dispatch, getChatMessage]);

  useEffect(() => {
    if (activeChat?.unreadTextMessagesCount > 0) {
      readAllTextMessages(activeChatId);
      dispatch(resetUnreadTextMessages(activeChatId));
    }
  }, [activeChat, activeChatId, readAllTextMessages, dispatch]);

  return (
    <div className={styles.messages}>
      <div className={styles.messagesList}>
        {
          (isLoading || isFetching) && (<ContentLoader />)
        }
        <ChatMessage>
          {
            messages?.map(({ id, isMyMessage, text, sendTime,  }) => (
              <ChatMessage.MessageItem
                key={id}
                text={text}
                fullName={activeChat.user.fullName?.[i18n.language] ?? t('Без имени')}
                isMyMessage={isMyMessage}
                sendTime={sendTime}
              />
            ))
          }
        </ChatMessage>
        <div style={{ float: 'left', clear: 'both' }} ref={boxRef} />
      </div>

      <div className={styles.messagesForm} ref={fieldRef} tabIndex={-1}>
        <Row align="bottom" gutter={16}>
          <Col xs={16} sm={14} md={14} lg={16} xl={18}>
            <Input.TextArea
              value={messageValue}
              onChange={(e) => {
                setMessageValue(e.target.value)
              }}
              ref={textAreaRef}
              disabled={isSendLoading || isSendFetching || isLoading || isFetching}
            />
          </Col>
          <Col xs={8} sm={10} md={10} lg={8} xl={6}>
            <Button
              type="primary"
              style={{ marginLeft: 'auto' }}
              block
              onClick={sendMessage}
              loading={isSendLoading || isSendFetching || isLoading || isFetching}
            >
              {t('Отправить')}
            </Button>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ChatBoxTextMessages;
