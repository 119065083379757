import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { getCredentials } from '../utils';
import routes from './routes';
// import cn from 'classnames';

const ProtectRoute = ({ children }) => {
  const navigate = useNavigate();
  const [pass, setPass] = useState(null);

  useEffect(() => {
    getCredentials()
      .then((credentials) => {
        if (!credentials || !credentials.accessToken) {
          navigate(routes.root(), { replace: true });
          return;
        }
        setPass(true);
      })
      .catch((err) => {
        navigate(routes.root(), { replace: true });
      })
  }, [navigate]);

  if (pass === null) return null;
  return pass ? children : null;
};

export default ProtectRoute;
