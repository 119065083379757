import { joinExisting } from '../utils';

export default {
  root: () => '/',
  app: () => '/app',
  welcome: () => '/app/welcome',
  profile: () => '/app/profile',
  workTime: () => '/app/work-time',
  workTimeCreate: () => '/app/work-time/create',
  billing: () => '/app/billing',
  withdrawals: () => '/app/withdrawals',
  withdrawalsDetails: (id) => `/app/withdrawals/${id}`,
  schedule: () => '/app/schedule',
  rescheduled: () => '/app/rescheduled',
  chats: () => '/app/chats',
  students: () => '/app/students',
  notifications: () => '/app/notifications',

  room: () => '/room',
};
