import React from 'react';
import cn from 'classnames';

import styles from './ContentLoader.module.scss';
import { Spin } from 'antd';

const ContentLoader = ({ label }) => {

  return (
    <div className={styles.loader}>
      <Spin tip={label} spinning size="large" />
    </div>
  );
};

export default ContentLoader;
