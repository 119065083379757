import React, { useCallback, useMemo } from 'react';
import { useGetRescheduledLessonListQuery } from '../../../store/apis/schedule';
import { Button, Col, Row, Space, Table, Tag, Typography } from 'antd';
import ContentLoader from '../../../components/ContentLoader';
import { useTranslation } from 'react-i18next';
import { formatDate } from '../../../utils';
import { ArrowDownOutlined } from '@ant-design/icons';
import useQueryString from '../../../hooks/useQueryString';
import { useNavigate } from 'react-router';
import queryString from 'query-string';
import ApproveReschedule from '../../../containers/ApproveReschedule';
import RejectReschedule from '../../../containers/RejectReschedule';
import ContentError from '../../../components/ContentError';

const { Title, Text, Paragraph } = Typography;

const RescheduledLessons = () => {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const { page = 1, pageSize = 10, ...restQuery } = useQueryString();

  const changePagination = useCallback((newPage, newPageSize) => {
    navigate({
      pathname: '',
      search: queryString.stringify({
        page: newPage,
        pageSize: newPageSize,
        ...restQuery,
      }),
    });
  }, [navigate, restQuery]);

  const {
    data: rescheduledLessons,
    isLoading: isListLoading,
    isFetching: isListFetching,
    error: listError,
  } = useGetRescheduledLessonListQuery({ page, pageSize });

  const statusColors = useMemo(() => ({
    success: 'green',
    primary: 'blue',
    warning: 'orange',
    danger: 'red',
  }), []);

  const columns = useMemo(() => [
    {
      title: t('Дата заявки'),
      dataIndex: 'createdTime',
      key: 'createdTime',
      render: (isoDate) => formatDate(isoDate, true),
    },
    {
      title: t('Статус'),
      dataIndex: 'status',
      key: 'status',
      render: (status) => {
        const colors = [null, 'warning', 'success', 'red'];
        const injectedColorIfNotExist = {
          color: colors[status.id],
          ...status,
        };

        return <Tag color={injectedColorIfNotExist.color}>{status?.name[i18n.language]}</Tag>;
      },
    },
    {
      title: t('Инициатор'),
      dataIndex: 'autorType',
      key: 'autorType',
      render: (author) => author?.name[i18n.language],
    },
    {
      title: t('Ученик'),
      dataIndex: 'student',
      key: 'student',
      render: (student) => student.fullName?.[i18n.language],
    },
    {
      title: t('Комментарий'),
      dataIndex: 'comment',
      key: 'comment',
      render: (text) => <Paragraph ellipsis={{ tooltip: text, rows: 3 }}>{text}</Paragraph>
    },
    {
      title: t('Дата переноса'),
      dataIndex: 'toLessonTime',
      key: 'toLessonTime',
      render: (toLessonTime, data) => (
        <Space direction="vertical" align="center">
          <Tag color="red">{formatDate(data.fromLessonTime, true)}</Tag>
          <ArrowDownOutlined />
          <Tag color="green">{formatDate(toLessonTime, true)}</Tag>
        </Space>
      ),
    },
    {
      title: t('Действия'),
      dataIndex: 'approveAvailable',
      key: 'approveAvailable',
      render: (canDoActions, data) => canDoActions && (
        <Space>
          <RejectReschedule id={data.id} />
          <ApproveReschedule id={data.id} />
        </Space>
      ),
    },
  ], [i18n.language, t]);

  if (isListLoading || isListFetching) return <ContentLoader label={t('Загружаю список уроков')} />
  if (listError) return <ContentError error={listError} />
  return (
    <Row>
      <Col xs={24}>
        <Title level={3}>{t('Уроки ожидающие переноса или отмены')}</Title>
      </Col>

      <Col xs={24}>
        <Table
          columns={columns}
          dataSource={rescheduledLessons?.results}
          rowKey="id"
          pagination={{
            total: rescheduledLessons?.count,
            current: page,
            showSizeChanger: true,
            pageSize,
            onChange: changePagination,
          }}
        />
      </Col>
    </Row>
  );
};

export default RescheduledLessons;
