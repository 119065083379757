import React from 'react';
import cn from 'classnames';

import styles from './Welcome.module.scss';
import { useTranslation } from 'react-i18next';

const Welcome = () => {
  const [t] = useTranslation();

  return (
    <div className={styles.welcome}>
      <div className={styles.welcomeText}>
        {t('Добро пожаловать')}
      </div>
      <div className={styles.welcomeIcon}>
        👋
      </div>

    </div>
  );
};

export default Welcome;
