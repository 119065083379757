import React, { useCallback, useState } from 'react';
import styles from './Billing.module.scss';
import { Col, DatePicker, Divider, Result, Row, Skeleton, Space, Statistic, Table, Typography } from 'antd';
import { useTranslation } from 'react-i18next';
import { useGetLessonsQuery, useGetStatisticsQuery } from '../../../store/apis/billing';
import { FundOutlined, FundViewOutlined, IssuesCloseOutlined, UserOutlined } from '@ant-design/icons';
import { gold, green, red, blue } from '@ant-design/colors';
import dayjs from 'dayjs';
import ContentLoader from '../../../components/ContentLoader';
import ContentError from '../../../components/ContentError';
import { formatDate, formatNumber, toSom } from '../../../utils';
import queryString from 'query-string';
import { useNavigate } from 'react-router';
import useQueryString from '../../../hooks/useQueryString';

const { Title, Text } = Typography;

const Billing = () => {
  const [t, i18n] = useTranslation();
  const [lessonDateDiapason, setLessonDiapason] = useState([dayjs().startOf('date'), dayjs().endOf('date')]);
  const navigate = useNavigate();

  const { page = 1, pageSize = 20, ...query } = useQueryString();

  const {
    data: statistics,
    isLoading: isStatisticsLoading,
    isFetching: isStatisticsFetching,
    error,
  } = useGetStatisticsQuery({
    fromTime: lessonDateDiapason[0].format(),
    toTime: lessonDateDiapason[1].format(),
  });

  const {
    data: lessons,
    isFetching: isLessonsFetching,
  } = useGetLessonsQuery({
    lessonTimeFrom: lessonDateDiapason[0].format(),
    lessonTimeTo: lessonDateDiapason[1].format(),
    page,
    pageSize,
  });

  const changeFilterDate = useCallback((dates) => {
    const [from, to] = dates;
    setLessonDiapason([from.startOf('date'), to.endOf('date')]);
  }, []);

  const rangePresets = [
    {
      label: t('За прошлую неделю'),
      value: [dayjs().subtract(1, 'week').startOf('week'), dayjs().subtract(1, 'week').endOf('week')],
    },
    {
      label: t('За прошлый месяц'),
      value: [dayjs().subtract(1, 'month').startOf('month'), dayjs().subtract(1, 'month').endOf('month')],
    },
    {
      label: t('За позапрошлый месяц'),
      value: [dayjs().subtract(2, 'month').startOf('month'), dayjs().subtract(2, 'month').endOf('month')],
    },
    {
      label: t('За последние 90 дней'),
      value: [dayjs().subtract(90, 'days'), dayjs()],
    },
  ];

  const columns = [
    {
      key: 'lessonTime',
      dataIndex: 'lessonTime',
      title: 'Дата и время урока',
      render: (date) => formatDate(date, true),
    },
    {
      key: 'incomeAmount',
      dataIndex: 'incomeAmount',
      title: 'Сумма пополнения баланса',
      render: (amount) => formatNumber(toSom(amount)),
    },
    {
      key: 'student',
      dataIndex: 'student',
      title: 'Студент',
      render: (student) => student.fullName?.[i18n.language]
    },
  ];

  const changePagination = useCallback((newPage, newPageSize) => {
    navigate({
      pathname: '',
      search: queryString.stringify({
        page: newPage,
        pageSize: newPageSize,
        ...query,
      }),
    });
  }, [navigate, query]);

  if (isStatisticsLoading) return <Skeleton loading />;
  if (error) return <ContentError error={error} />;

  return (
    <div className={styles.wrapper}>
      {
        isStatisticsFetching && (
          <div className={styles.loader}>
            <ContentLoader />
          </div>
        )
      }
      <Row gutter={16} style={{ marginBottom: 42 }}>
        <Col xs={20}>
          <Title level={3}>{t('Статистика')}</Title>
          <Space>
            <Text>{t('Статистика за период')}</Text>
            <DatePicker.RangePicker
              presets={rangePresets}
              format="DD.MM.YYYY"
              onChange={changeFilterDate}
              value={lessonDateDiapason}
              allowClear={false}
            />

          </Space>
        </Col>
        <Col xs={4}>
          <Statistic
            title={t('Текущий баланс')}
            value={statistics.data.balance}
            groupSeparator="'"
            suffix={t('сум')}
            prefix={statistics.data.balance > 0 ? '+' : null}
            valueStyle={{ fontWeight: 700, color: blue.primary }}
          />
        </Col>
      </Row>
      <Row gutter={16}>
        <Col lg={8}>
          <Statistic
            title={t('Заработано')}
            value={statistics.data.totalIncome}
            groupSeparator="'"
            suffix={t('сум')}
            valueStyle={{ color: gold.primary }}
          />
        </Col>
        <Col lg={8}>
          <Statistic
            title={t('Получено')}
            value={statistics.data.totalWithdrawal}
            groupSeparator="'"
            suffix={t('сум')}
            valueStyle={{ color: green.primary }}
          />
        </Col>
        <Divider />
        <Col lg={8}>
          <Statistic
            title={t('Количество студентов')}
            value={statistics.data.studentCount}
            prefix={<UserOutlined />}
          />
        </Col>
        <Col lg={8}>
          <Statistic
            title={t('Проведено уроков')}
            value={statistics.data.finishedLessonCount}
            prefix={<FundOutlined />}
          />
        </Col>
        <Col lg={8}>
          <Statistic
            title={t('Из них бесплатных')}
            value={statistics.data.freeLessonCount}
            prefix={<FundViewOutlined />}
          />
        </Col>
        <Divider />
        <Col lg={8}>
          <Statistic
            title={t('Аннулировано уроков на сумму')}
            value={statistics.data.annulledAmount}
            groupSeparator="'"
            suffix={t('сум')}
            valueStyle={{ color: red.primary }}
          />
        </Col>
        <Col lg={8}>
          <Statistic
            title={t('Аннулировано уроков')}
            value={statistics.data.annulledLessonCount}
            prefix={<IssuesCloseOutlined />}
            valueStyle={{ color: red.primary }}
          />
        </Col>
      </Row>
      <Row gutter={12} style={{ marginTop: 20 }}>
        <Col md={24}>
          <Typography.Title level={4}>
            {t('Уроки за этот период')}
          </Typography.Title>
        </Col>
        <Col md={24}>
          <Table
            columns={columns}
            dataSource={lessons?.results}
            loading={isLessonsFetching}
            pagination={{
              total: lessons?.count,
              current: page,
              showSizeChanger: true,
              pageSize,
              onChange: changePagination,
            }}
          />
        </Col>
      </Row>
    </div>
  );
};

export default Billing;
