// import cn from 'classnames';

import styles from './RoomVideoControls.module.scss';
import React from 'react';
import { useMediaState } from '../../../store/slicers/room';
import useMedia from '../../../rtc/hooks/useMedia';
import { LuScreenShare, LuScreenShareOff } from 'react-icons/lu';
import EventType from '../../../rtc/EventType';
import usePeerConnection from '../../../rtc/hooks/usePeerConnection';

const ScreenShareButton = () => {
  const mediaState = useMediaState();
  const MediaDevice = useMedia();
  const PeerConnection = usePeerConnection();

  const onShare = () => {
    if (mediaState.isScreenShareEnabled) {
      const userMediaStream = MediaDevice.stream;
      const track = userMediaStream.getVideoTracks()[0];
      PeerConnection.replaceTrack(track);
      MediaDevice.stopDisplayMedia();
    } else {
      const selfVideoPlayer = document.getElementById('self-video-player');
      MediaDevice.startDisplayMedia()
        .on(EventType.SCREEN_STREAM, (stream) => {
          if (selfVideoPlayer) {
            selfVideoPlayer.srcObject = stream;
          }
          const track = stream.getVideoTracks()[0];
          PeerConnection.replaceTrack(track);
        })
        .on(EventType.STOPPED_SCREEN_STREAM, () => {
          const userMediaStream = MediaDevice.stream;
          const track = userMediaStream.getVideoTracks()[0];
          PeerConnection.replaceTrack(track);
        })
    }
  };

  return (
    <button
      className={styles.controlsButton}
      onClick={onShare}
    >
      {
        mediaState.isScreenShareEnabled
          ? <LuScreenShareOff/>
          : <LuScreenShare />
      }
    </button>
  );
};

export default ScreenShareButton;
