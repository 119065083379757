import React from 'react';

import styles from './Profile.module.scss';
import { Card, Col, Empty, Row, Space, Tag, Typography } from 'antd';
import LabelData from '../../../components/LabelData';
import { formatDate, formatNumber, joinExisting } from '../../../utils';
import ReactPlayer from 'react-player';
import { useTranslation } from 'react-i18next';
import { useGetMeQuery } from '../../../store/apis/auth';
import ContentLoader from '../../../components/ContentLoader';
import ContentError from '../../../components/ContentError';

const { Title, Text } = Typography

const Profile = () => {
  const [t, i18n] = useTranslation();

  const {
    data: tutor,
    isLoading,
    isFetching,
    error,
  } = useGetMeQuery();

  if (isLoading || isFetching) return <ContentLoader />
  if (error) return <ContentError error={error} />

  const {
    subjects,
    learningGoals,
    educations,
    certificates,
    workExperiences,
    languageSkills,
  } = tutor;

  return (
    <div className={styles.tutor}>
      <Title level={2}>
        {joinExisting([
          tutor.lastName?.[i18n.language],
          tutor.firstName?.[i18n.language],
          tutor.secondName?.[i18n.language],
        ], ' ')}
      </Title>
      <Row gutter={12} align="top">
        <Col xs={24} md={6}>
          <div>
            <Card>
              <div className={styles.tutorPhoto}>
                <img src={tutor.photoUrl} alt=""/>
              </div>
              <LabelData label={t('ID')} data={tutor.id} />
              <LabelData
                label={t('Состояние')}
                data={
                  <Tag color={tutor.isPublished ? 'green' : 'red'}>
                    {tutor.isPublished ? t('Активный') : t('Неактивный')}
                  </Tag>
                }
              />
              <LabelData label={t('Дата добавления в систему')} data={formatDate(tutor.teachingSinceDate)} />
              <LabelData label={t('Рейтинг')} data={`${tutor.rating ?? 0} (${tutor.reviewCount})`} />
              <LabelData label={t('Количество проведенных уроков')} data={tutor.completedLessonCount} />
            </Card>
          </div>
        </Col>

        <Col xs={24} md={18}>
          <Space direction="vertical" size="middle" style={{ width: '100%' }}>
            <Card title={t('Персональные данные')} type="inner">
              <LabelData label={t('Дата рождения')} data={formatDate(tutor.birthdate)} />
              <LabelData label={t('Цена за час')} data={formatNumber(tutor.price, t('сум'))} />
              <LabelData label={t('Обучает с')} data={formatDate(tutor.teachingSinceDate)} />
              <LabelData
                label={t('Предметы обучения')}
                data={
                  (subjects && subjects.length > 0)
                    ? subjects.map((subject) => (
                      <Tag color="blue" key={subject.id}>{subject.name[i18n.language]}</Tag>
                    )) : null
                }
              />
              <LabelData
                label={t('Цели обучения')}
                data={
                  (learningGoals && learningGoals.length > 0)
                    ? learningGoals.map((learningGoal) => (
                      <Tag color="blue" key={learningGoal.id}>{learningGoal.name[i18n.language]}</Tag>
                    )) : null
                }
              />
              <LabelData
                label={t('Приветственный текст')}
                data={tutor.introductionText?.[i18n.language]}
              />
              <LabelData
                label={t('Приветственное видео')}
                data={
                  <ReactPlayer
                    controls
                    url={tutor.introductionVideoUrl}
                    width="320px"
                    height="180px"
                  />
                }
              />
            </Card>
            <Card type="inner" title={t('Образование')}>
              {
                (educations && educations.length > 0)
                  ? educations.map((education) => (
                    <LabelData
                      key={education.id}
                      label={joinExisting([education.fromYear, education.toYear], ' - ')}
                      data={
                        joinExisting([
                          education.educationInstitution?.name[i18n.language],
                          education.educationLevel?.name[i18n.language],
                          education.speciality?.[i18n.language],
                        ], ', ')
                      }
                    />
                  )) : <Empty description={t('Нет данных')} />
              }
            </Card>
            <Card type="inner" title={t('Сертификаты')}>
              {
                (certificates && certificates.length > 0)
                  ? certificates.map((certificate) => (
                    <LabelData
                      key={certificate.id}
                      label={certificate.year}
                      data={
                        joinExisting([
                          certificate.issuer?.[i18n.language],
                          certificate.name?.[i18n.language],
                          certificate.score,
                        ], ', ')
                      }
                    />
                  )) : <Empty description={t('Нет данных')} />
              }
            </Card>
            <Card type="inner" title={t('Опыт работы')}>
              {
                (workExperiences && workExperiences.length > 0)
                  ? workExperiences.map((workExperience) => (
                    <LabelData
                      key={workExperience.id}
                      label={`${workExperience.fromYear} - ${workExperience.toYear}`}
                      data={
                        joinExisting([
                          workExperience.place?.[i18n.language],
                          workExperience.position?.[i18n.language],
                        ], ', ')
                      }
                    />
                  )) : <Empty description={t('Нет данных')} />
              }
            </Card>
            <Card type="inner" title={t('Владение языками')}>
              {
                (languageSkills && languageSkills.length > 0)
                  ? languageSkills.map((languageSkill) => (
                    <LabelData
                      key={languageSkill.id}
                      label={languageSkill.language?.name?.[i18n.language]}
                      data={languageSkill.languageSkillLevel?.name[i18n.language]}
                    />
                  )) : <Empty description={t('Нет данных')} />
              }
            </Card>
          </Space>
        </Col>
      </Row>
    </div>
  );
};

export default Profile;
