import React, { useCallback, useState } from 'react';
import { Badge, Button, List, Popconfirm, Radio, Skeleton, Space, Typography } from 'antd';

import styles from './Notifications.module.scss';
import { useTranslation } from 'react-i18next';
import {
  useGetNotificationsQuery,
  useReadAllNotificationsMutation,
  useReadOneNotificationMutation
} from '../../../store/apis/notifications';
import useQueryString from '../../../hooks/useQueryString';
import ContentError from '../../../components/ContentError';
import { useNavigate } from 'react-router';
import queryString from 'query-string';
import { formatDate } from '../../../utils';

const { Text, Title } = Typography;

const Notifications = () => {
  const [t, i18n] = useTranslation();
  const navigate = useNavigate();
  const {
    page = 1,
    pageSize = 20,
    readState = 'all',
    ...restQuery
  } = useQueryString();


  const {
    data: notification,
    isLoading,
    isFetching,
    error,
  } = useGetNotificationsQuery({
    page,
    pageSize,
    ...restQuery,
  });

  const [
    readAllNotification,
  ] = useReadAllNotificationsMutation();

  const [
    readNotification,
  ] = useReadOneNotificationMutation();

  const handleChangeIsRead = useCallback((ev) => {
    const rootQuery = {
      page,
      pageSize,
      readState: ev.target.value,
      ...restQuery,
    };

    switch (ev.target.value) {
      case 'unread':
        rootQuery.isRead = false;
        break;
      case 'read':
        rootQuery.isRead = true;
        break;
      default:
        rootQuery.isRead = null;
        break;
    }

    navigate({
      pathname: '',
      search: queryString.stringify(rootQuery),
    });

  }, [navigate, page, pageSize, restQuery]);

  const changePagination = useCallback((newPage, newPageSize) => {
    navigate({
      pathname: '',
      search: queryString.stringify({
        page: newPage,
        pageSize: newPageSize,
        readState,
        ...restQuery,
      }),
    });
  }, [navigate, readState, restQuery]);

  const handleReadOneNotification = useCallback((id) => async () => {
    try {
      await readNotification({ id, params: { page, pageSize, ...restQuery } }).unwrap();
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [page, pageSize, readNotification, restQuery]);

  const handleReadAllNotification = useCallback(async () => {
    try {
      await readAllNotification({ page, pageSize, ...restQuery }).unwrap();
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [page, pageSize, readAllNotification, restQuery]);

  if (error) return <ContentError error={error} />

  return (
    <div className={styles.notifications}>
      <Title level={3}>{t('Уведомления')}</Title>
      <div className={styles.notificationsFilters}>
        <Radio.Group
          defaultValue="all"
          buttonStyle="solid"
          value={readState}
          onChange={handleChangeIsRead}
        >
          <Radio.Button value="all">{t('Все')}</Radio.Button>
          <Radio.Button value="unread">{t('Непрочитанные')}</Radio.Button>
          <Radio.Button value="read">{t('Прочитанные')}</Radio.Button>
        </Radio.Group>

        <Popconfirm
          title={t('Вы уверены?')}
          cancelText={t('Нет')}
          okText={t('Да')}
          onConfirm={handleReadAllNotification}
        >
          <Button type="primary" htmlType="button">
            {t('Пометить всё как прочитанное')}
          </Button>
        </Popconfirm>
      </div>
      {
        (isLoading || isFetching)
          ? <Skeleton loading />
          : (
           <div>
             <List
               itemLayout="horizontal"
               dataSource={notification?.results}
               pagination={{
                 total: notification?.count,
                 current: page,
                 showSizeChanger: true,
                 pageSize,
                 onChange: changePagination,
               }}
               renderItem={(item) => (
                 <div key={item.id} className={styles.notificationsItem}>
                   <Space direction="vertical">
                     <Text type="secondary">
                       {formatDate(item.sendTime, true)}
                     </Text>
                     {
                       item.isRead
                         ? (
                           <Text strong>{item.title[i18n.language]}</Text>
                         ) : (
                           <Badge
                             color="blue"
                             text={<Text strong>{item.title[i18n.language]}</Text>}
                           />
                         )
                     }
                     <Text>{item.text[i18n.language]}</Text>
                   </Space>
                   {
                     !item.isRead && (
                       <Button
                         type="link"
                         htmlType="button"
                         onClick={handleReadOneNotification(item.id)}
                       >
                         {t('Пометить как прочитанное')}
                       </Button>
                     )
                   }
                 </div>
               )}
             />
           </div>
          )
      }
    </div>
  );
};

export default Notifications;
