import React, { useCallback } from 'react';
import cn from 'classnames';

import styles from './ApproveReschedule.module.scss';
import { Button, message, Popconfirm } from 'antd';
import { useTranslation } from 'react-i18next';
import { useApproveRescheduleMutation } from '../../store/apis/schedule';

const ApproveReschedule = ({ id }) => {
  const [t] = useTranslation();

  const [approveReschedule, { isLoading, isFetching }] = useApproveRescheduleMutation();

  const handleOk = useCallback(async () => {
    try {
      await approveReschedule(id).unwrap();
      message.success(t('Вы согласились на перенос - Урок перенесен'));
    } catch (e) {
      console.log(JSON.stringify(e.data ?? e, 0, 2));
    }
  }, [approveReschedule, id, t]);

  return (
    <Popconfirm
      title={t('Вы уверены?')}
      description={t('Урок будет перенесен на другую дату')}
      okText={t('Да, перенести')}
      cancelText={t('Нет')}
      onConfirm={handleOk}
      okButtonProps={{
        loading: isLoading || isFetching,
        disabled: !id,
      }}
    >
        <Button
          htmlType="button"
          size="small"
          type="link"
        >
          {t('Подтвердить')}
        </Button>
    </Popconfirm>
  );
};

export default ApproveReschedule;
