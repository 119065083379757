import { invalidateOn } from '../utils';
import apiRoutes from '../../apiRoutes';

import { mainApi } from './mainApi';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getSchedule: build.query({
      query: (params) => ({
        url: apiRoutes.schedule(),
        params,
      }),
      providesTags: ['SCHEDULE'],
    }),
    getLessonSchedules: build.query({
      query: (params) => ({
        url: apiRoutes.lessonSchedules(),
        params,
      }),
      providesTags: ['LESSON_SCHEDULES'],
    }),
    getRescheduledLessonList: build.query({
      query: (params) => ({
        url: apiRoutes.rescheduledLessonList(),
        params,
      }),
      providesTags: ['RESCHEDULED_LESSONS'],
    }),

    cancelLesson: build.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.cancelLesson(id),
        body,
        method: 'POST',
      }),
      invalidatesTags: invalidateOn({ success: ['SCHEDULE'] })
    }),
    rescheduleLesson: build.mutation({
      query: ({ id, body }) => ({
        url: apiRoutes.rescheduleLesson(id),
        body,
        method: 'POST',
      }),
      invalidatesTags: invalidateOn({ success: ['SCHEDULE'] })
    }),

    approveReschedule: build.mutation({
      query: (id) => ({
        url: apiRoutes.approveReschedule(id),
        method: 'POST',
      }),
      invalidatesTags: invalidateOn({ success: ['RESCHEDULED_LESSONS'] })
    }),
    rejectReschedule: build.mutation({
      query: (id) => ({
        url: apiRoutes.rejectReschedule(id),
        method: 'POST',
      }),
      invalidatesTags: invalidateOn({ success: ['RESCHEDULED_LESSONS'] })
    }),

    startLesson: build.mutation({
      query: ({ body, scheduleId }) => ({
        url: apiRoutes.startLesson(scheduleId),
        method: 'POST',
        body,
      }),
    }),
    finishLesson: build.mutation({
      query: (lessonId) => ({
        url: apiRoutes.startLesson(lessonId),
        method: 'POST',
      }),
    }),

  })
})

export const {
  useLazyGetScheduleQuery,
  useGetRescheduledLessonListQuery,
  useCancelLessonMutation,
  useRescheduleLessonMutation,
  useApproveRescheduleMutation,
  useRejectRescheduleMutation,
  useStartLessonMutation,
  useFinishLessonMutation,
  useGetLessonSchedulesQuery,
} = mainApi;
