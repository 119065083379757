import { invalidateOn } from '../utils';
import apiRoutes from '../../apiRoutes';
import { mainApi } from './mainApi';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    getStatistics: build.query({
      query: (params) => ({
        url: apiRoutes.statistics(),
        params,
      }),
      providesTags: ['STATISTICS'],
    }),
    getWithdrawals: build.query({
      query: (params) => ({
        url: apiRoutes.withdrawals(),
        params,
      }),
      providesTags: ['WITHDRAWALS'],
    }),
    getLessons: build.query({
      query: (params) => ({
        url: apiRoutes.lessons(),
        params,
      }),
      providesTags: ['LESSONS'],
    }),

    getPaychecks: build.query({
      query: (params) => ({
        url: apiRoutes.paychecks(),
        params,
      }),
      providesTags: ['PAYCHECKS'],
    }),
    getPaycheckDetails: build.query({
      query: (id) => ({
        url: apiRoutes.paychecks(id),
      }),
      providesTags: ['PAYCHECK_DETAILS'],
    }),
  }),
})

export const {
  useGetStatisticsQuery,
  useGetWithdrawalsQuery,
  useGetLessonsQuery,
  useGetPaychecksQuery,
  useGetPaycheckDetailsQuery,
} = mainApi;
