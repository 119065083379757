import { mainApi } from './mainApi';
import apiRoutes from '../../apiRoutes';

mainApi.injectEndpoints({
  endpoints: (build) => ({
    createRoom: build.mutation({
      query: ({ roomId, studentId }) => ({
        url: apiRoutes.createRoom(roomId),
        body: { studentId },
        method: 'PUT',
      }),
    }),

    addIceCandidate: build.mutation({
      query: ({ roomId, body }) => ({
        url: apiRoutes.addIce(roomId),
        method: 'POST',
        body,
      }),
    }),

    setOffer: build.mutation({
      query: ({ roomId, body }) => ({
        url: apiRoutes.setOffer(roomId),
        method: 'PUT',
        body,
      }),
    }),
  }),
});

export const {
  useCreateRoomMutation,
  useAddIceCandidateMutation,
  useSetOfferMutation,
} = mainApi;
