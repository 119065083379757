import { createApi } from '@reduxjs/toolkit/query/react';
import { baseQuery } from '../utils';


export const mainApi = createApi({
  reducerPath: 'main',
  baseQuery: baseQuery,
  tagTypes: [],
  endpoints: () => ({}),
});
